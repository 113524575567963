.row-quiz {
    .progress {
        width: 80px;
        height: 14px;
        font-size: 11px;

        .progress-bar {
            background-color: $purple-color
        }
    }

    .avatar {
        margin-left: 15px;

        img {
            width: 40px;
            height: 40px;
        
        }           
    }
    .avatar-mini {
        margin-left: 10px;

        img {
            width: 25px;
            height: 25px;
        
        }           
    }

    .details {
        display: flex;
        flex-direction: column;
    }

    .details-refresh {
        display: flex;
        align-items: center;
       
        
        i {
            margin-right: 5px;
        }
    }
}

