.home-navbar {
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;

    h2 {
        color: $v2-default-color;
        margin: 0px;
    }

    .navbar-children {
        flex: 1;

        @include mobile {
            .v2-add-icon {
                font-size: 0px;

                .icon-container {
                    margin-right: 0px;
                }
            }
        }
    }

    .toggle-button-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;

        .avatar-user-container {
            margin-right: 20px;

            @include mobile {
                margin-right: 0px;
                .avatar-info {
                    display: none;
                }

                .avatar-img {
                    margin-right: 0px;
                    max-width: initial;
                }
            }
        }

        svg {
            color: $v2-primary-color;

            @include mobile {
                display: none;
            }
        }
    }

    .logo {
        display: none;
        @include mobile {
            display: flex;
            width: 100%;
            align-items: center;

            .icon {
                color: $v2-primary-color;
                margin-right: 10px;
            }

            .logo-img {
                max-width: 80px;
            }
        }
    }

    .breadcrumb-header {
        display: flex;
        .breadcrumbs {
            @include mobile {
                display: none;
            }
        }
    }
}

.breadcrumb-mobile {
    display: none;
    @include mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        margin-top: 10px;

        svg {
            display: none;
        }
        
        h2 {
            margin-bottom: 0px;
        }
    }
}

.sidebar-mini {
    .sidebar {
        .logo > a:first-child {
            display: none;

            @include mobile {
                display: block;
            }
        }

        &:hover {
            .logo > a:first-child {
                display: block;
            }
        }
    }
}

.sidebar {
    .logo {
        padding-right: 0px;
    }
    .menu-adminnav {
        color: white;
        margin-top: 10px;
        margin-right: 10px;
    }
    .arrow-adminnav {
        display: none;
    }
    @include mobile {
        .menu-adminnav {
            display: none;
        }
        .arrow-adminnav {
            color: white;
            margin-top: 10px;
            margin-right: 10px;
            display: inline-block;
        }
    }

    .sidebar-wrapper::-webkit-scrollbar {
        display: none;
    }
}

@include mobile {
    .sidebar:before {
        display: none !important;
    }

    .sidebar:after {
        display: none !important;
    }
}
