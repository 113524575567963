.div-terms {
    padding : 40px;
    height  : calc(100vh - 228px);
    overflow: auto;
}

.div-terms-auth {
    padding: 40px;
}

.div-primary-auth-terms {
    z-index         : 99999;
    background-color: white;
    position        : relative;
}

.div-primary-terms {

    background-color: white;
    position        : relative;
}

.footer-terms {
    bottom                 : 0px;
    width                  : 100%;
    background-color       : white;
    height                 : 150px;
    border-top-left-radius : 15px;
    border-top-right-radius: 15px;
}

.p-terms {
    display        : flex;
    justify-content: center;
    padding-top    : 20px;
}

.div-btn {
    flex-direction : row;
    display        : 'flex';
    align-items    : center;
    justify-content: center;
    display        : grid;
}