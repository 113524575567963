.react-select {
  &.zIndexTop {
    z-index: 999999998;

    .react-select__control {
      background-color: white !important;
    }

    &.top {
      z-index: 999999999;
    }
  }

  &.creatable-custom {
    .option-item {
      position: relative;
    }

    .remove-tag {
      position: absolute;
      right: 20px;
      top: 15px;

      &.danger {
        color: $danger-color;
        margin-right: 5px;

        &:hover {
          color: $danger-color-opacity;
        }
      }
    }

    .input-tag {
      [contenteditable=true]:empty:before {
        content: attr(placeholder);
        color: gray;
      }

      /* found this online --- it prevents the user from being able to make a (visible) newline */
      [contenteditable=true] br {
        display: none;
      }

      // border: 1px solid $dark-gray-opacity !important;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        font-size: 13px;
        color: $dark-gray;
        border: none;

        &:active,
        &:hover,
        &:focus {
          outline: none !important;
        }

        border-right: 1px solid $dark-gray-opacity;
        height: 22px;
        padding-left: 5px;
        padding-right: 5px;
        background-color: transparent;
      }

      .success {
        color: $success-color;
        margin-right: 5px;
        margin-left: 5px;

        &:hover {
          color: $success-color-opacity;
        }
      }

      .danger {
        color: $danger-color;
        margin-right: 5px;

        &:hover {
          color: $danger-color-opacity;
        }
      }

      .danger-simple {
        color: $dark-gray;
        margin-right: 5px;

        &:hover {
          color: $dark-gray-opacity;
        }
      }

      &.react-select__multi-value {
        height: 27px;
        font-size: 13px;
        padding-left: 0px;
      }
    }
  }

  .multi-value-container {
    margin: 0px !important;
    border-width: 0px !important;
  }

  .react-select__menu {
    top: 105%;
    max-width: 94%;
    width: 94%;
    right: 3%;
    border-radius: 12px;
    border: 0;
    box-shadow: 1px 2px 7px 1px rgba(0, 0, 0, .125);
    transition: all .3s cubic-bezier(.215, .61, .355, 1) 0s, opacity .3s ease 0s, height 0s linear .35s;
    background-color: $white-bg;
    box-sizing: border-box;
    max-height: 200px;
    position: absolute;
    z-index: 2;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 10px;
    margin-bottom: unset;
    margin-top: unset;

    &:before {
      border-bottom: 11px solid #f1eae0;
      top: -11px;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      content: "";
      display: inline-block;
      position: absolute;
      right: 15px;
    }

    &:after {
      top: -10px;
      border-bottom: 11px solid #fffcf5;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      content: "";
      display: inline-block;
      position: absolute;
      right: 15px;
    }
  }

  .react-select__menu-list {
    max-height: 198px;
    overflow-y: auto;
    padding-bottom: unset;
    padding-top: unset;
    position: relative;
  }

  .react-select__option {
    font-size: 0.8571em;
    padding-top: .6rem;
    padding-bottom: .6rem;
    margin-top: 5px;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: block;
    outline: none;
    color: #292b2c;
  }

  .react-select__menu {
    .react-select__option {

      &,
      &:active {
        background-color: transparent;
      }

      &.react-select__option--is-selected {

        &,
        &.react-select__option--is-focused {

          &,
          &:active {
            background-color: $medium-gray;
          }
        }
      }

      &.react-select__option--is-focused {

        &,
        &:active {
          background-color: $smoke-bg;
        }
      }

      &.react-select__option--is-disabled {

        &,
        &:active {
          color: #cccccc;
          cursor: default;
        }
      }
    }
  }

  .react-select__control {
    border-radius: 4px;
    min-height: 40px;
    height: 40px;
    box-sizing: border-box;
    border-collapse: separate;
    display: table;
    width: 100%;

    &,
    &:hover {
      border: 1px solid $light-gray;
      box-shadow: none;
      background-color: transparent;
    }

    &.react-select__control--is-focused {
      .react-select__dropdown-indicator {
        &:after {
          transform: rotate(180deg);
          top: -4px;
        }
      }

      &,
      &:hover {
        border: 1px solid $primary-color;
        box-shadow: none;
      }
    }

    .react-select__value-container--has-value {
      .react-select__multi-value {
        display: inline-flex;
        border-radius: 4px;
        background-color: transparent;
        margin: 6px;

        &+div:not(.react-select__multi-value) {
          display: inline-flex;
        }

        .react-select__multi-value__label {
          font-size: 0.9em;
        }
      }

      .react-select__single-value {
        top: 48%;
        color: $black-color;
      }
    }

    .react-select__placeholder {
      color: $dark-gray;
      line-height: normal;
      font-size: 0.8571em;
      top: 48%;
    }

    .react-select__value-container,
    .react-select__indicators {
      display: table-cell;
    }

    .react-select__indicators {
      width: 60px;
      padding-right: 10px;
      align-items: flex-end;
      vertical-align: middle;
      text-align: right;

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicator {
        padding: 0;
        display: inline-block;
      }

      .react-select__dropdown-indicator {
        &:after {
          transition: all 150ms ease 0s;
          border-color: #999 transparent transparent;
          border-style: solid;
          border-width: 5px 5px 2.5px;
          display: inline-block;
          height: 0;
          width: 0;
          position: relative;
          content: "";
        }

        &:hover:after {
          border-top-color: $link-disabled-color;
        }

        &>* {
          display: none;
        }
      }

      .react-select__clear-indicator {
        &:before {
          content: "\D7";
          display: inline-block;
          font-size: 18px;
          line-height: 1;
          top: 2px;
          position: relative;
          margin-right: 3px;
        }

        &>* {
          display: none;
        }
      }
    }
  }

  &.default {
    .react-select__control {
      &.react-select__control--is-focused {

        &,
        &:hover {
          border-color: $default-color;
        }
      }

      .react-select__value-container--has-value {

        .react-select__single-value,
        .react-select__multi-value__label {
          color: $default-color;
        }
      }
    }

    .react-select__multi-value {
      border: 1px solid $default-color;

      .react-select__multi-value__remove {
        color: $default-color-opacity;
        border-left: 1px solid $default-color-opacity;

        &:hover {
          color: $default-color;
          background-color: transparent;
        }
      }
    }
  }

  &.dark-gray {
    .react-select__control {
      &.react-select__control--is-focused {
        z-index: 99;

        &,
        &:hover {
          border-color: $dark-gray;
        }
      }

      .react-select__value-container--has-value {

        .react-select__single-value,
        .react-select__multi-value__label {
          color: $dark-gray;
        }
      }
    }

    .react-select__multi-value {
      border: 1px solid $dark-gray;

      .react-select__multi-value__remove {
        color: $dark-gray-opacity;
        border-left: 1px solid $dark-gray-opacity;

        &:hover {
          color: $dark-gray;
          background-color: transparent;
        }
      }
    }
  }

  &.primary {
    .react-select__control {
      &.react-select__control--is-focused {

        &,
        &:hover {
          border-color: $primary-color;
        }
      }

      .react-select__value-container--has-value {

        .react-select__single-value,
        .react-select__multi-value__label {
          color: $primary-color;
        }
      }
    }

    .react-select__multi-value {
      border: 1px solid $primary-color;

      .react-select__multi-value__remove {
        color: $primary-color-opacity;
        border-left: 1px solid $primary-color-opacity;

        &:hover {
          color: $primary-color;
          background-color: transparent;
        }
      }
    }
  }

  &.success {
    .react-select__control {
      &.react-select__control--is-focused {

        &,
        &:hover {
          border-color: $success-color;
        }
      }

      .react-select__value-container--has-value {

        .react-select__single-value,
        .react-select__multi-value__label {
          color: $success-color;
        }
      }
    }

    .react-select__multi-value {
      border: 1px solid $success-color;

      .react-select__multi-value__remove {
        color: $success-color-opacity;
        border-left: 1px solid $success-color-opacity;

        &:hover {
          color: $success-color;
          background-color: transparent;
        }
      }
    }
  }

  &.info {
    .react-select__control {
      &.react-select__control--is-focused {

        &,
        &:hover {
          border-color: $info-color;
        }
      }

      .react-select__value-container--has-value {

        .react-select__single-value,
        .react-select__multi-value__label {
          color: $info-color;
        }
      }
    }

    .react-select__multi-value {
      border: 1px solid $info-color;

      .react-select__multi-value__remove {
        color: $info-color-opacity;
        border-left: 1px solid $info-color-opacity;

        &:hover {
          color: $info-color;
          background-color: transparent;
        }
      }
    }
  }

  &.warning {
    .react-select__control {
      &.react-select__control--is-focused {

        &,
        &:hover {
          border-color: $warning-color;
        }
      }

      .react-select__value-container--has-value {

        .react-select__single-value,
        .react-select__multi-value__label {
          color: $warning-color;
        }
      }
    }

    .react-select__multi-value {
      border: 1px solid $warning-color;

      .react-select__multi-value__remove {
        color: $warning-color-opacity;
        border-left: 1px solid $warning-color-opacity;

        &:hover {
          color: $warning-color;
          background-color: transparent;
        }
      }
    }
  }

  &.danger {
    .react-select__control {
      &.react-select__control--is-focused {

        &,
        &:hover {
          border-color: $danger-color;
        }
      }

      .react-select__value-container--has-value {

        .react-select__single-value,
        .react-select__multi-value__label {
          color: $danger-color;
        }
      }
    }

    .react-select__multi-value {
      border: 1px solid $danger-color;

      .react-select__multi-value__remove {
        color: $danger-color-opacity;
        border-left: 1px solid $danger-color-opacity;

        &:hover {
          color: $danger-color;
          background-color: transparent;
        }
      }
    }
  }

  .react-select__multi-value {
    border: 1px solid $dark-gray;

    .react-select__multi-value__remove {
      position: relative;
      color: $opacity-gray-8;
      border-left: 1px solid $opacity-gray-8;

      &:hover {
        color: $dark-gray;
        background-color: transparent;
      }
    }
  }

  &.react-select--is-disabled {
    .react-select__multi-value--is-disabled {
      .react-select__multi-value__remove {
        display: none;
      }

      &.react-select__multi-value {
        background-color: #fcfcfc;
        border: 1px solid #e3e3e3;
      }
    }

    .react-select__control--is-disabled {
      background-color: #f9f9f9;
    }
  }
}

/* .react-select .react-select__menu {
  border-radius: 0px !important;
  width: 95%;
  max-width: 95%;
}

.react-select .react-select__option {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #5f5f5f;

} */