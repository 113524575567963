.group-selection { 
    width: 180px;
    font-size: 15px;


    .react-select{
        .react-select__control{
            background-color: white;
            border: 1px solid #DDDDDD;
            border-radius: 4px;            

            .react-select__placeholder {
                color: #66615b;
            }
        }
    }
}
