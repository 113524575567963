.chat-view{
    background-color:'#fff' !important;
    flex:1;
}
.chat-row{
    display:flex;
    flex-direction: row;
    flex:1;
    height:97vh;
}

.chat-collumn{
    display:flex;
    flex-direction: column;
    flex:1
}

.pn-channel__thumb{
    height:40px;
    width: 40px;
}
.div-search{
    margin: 10px;
    margin-bottom:50px
}
.pn-channel-list{
    height:auto;
    overflow-x: hidden;
}

.div-list{
    min-width: 300px;
    overflow: auto;
    height: calc(100vh - 225px);
    position: relative;
    // overflow: hidden;
    overflow: auto;

    // @media (max-height:700px) {
    //     height: 60%;
    // }
    // @media (max-height:477px) {
    //     height: 40%;
    // }
    // @media (max-height:323px) {
    //     height: 20%;
    // }
}

.pn-channel-list{
    padding-top:15px;
}

.span-list{
    margin: 15px;
}
.div-image{
    margin:15px;
    display:flex;
    align-items: center;
}

.img-avatar{
    width: 36px;
    height: 36px;
    border-radius: 40px;
}
.div-form{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.txt-user{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: 400;

}

.txt-user-info{
    
    font-size: 10px;
    font-weight: 400;
}
.img-chat{
    // height: 150px;
    max-width: 600px;
    margin: 15px 0 0;
    border-radius:10px;

}

.chat-end{
    padding: 10px 16px;
    display: flex;
    justify-content: flex-end;
    &:hover{
       background:#e9eef4
    }
}
.chat-start{
    padding: 10px 16px;
    display: flex;
    justify-content: flex-start;
    &:hover{
       background:#e9eef4
    }
}

.div-row-blue{
    display: flex;
    align-items: flex-start;
 background-color: #0084ff;
 border-radius: 15px;
}
.div-row-gray{
    display: flex;
    align-items: flex-start;
 background-color: #fff;
 border-radius: 15px;
}

.div-collumn-chat{
    display:flex;
    flex-direction: column;
    flex:1;
    margin:10px
}

.div-space{
    display: flex;
    justify-content: space-between;
    margin:0 0 10px;
}
.time{
    font-size:11px;
    // color:white;
    // font-weight: 300;
}

.msg{
    font-size:13px;
    width: 90%;

}
.nm-work{
    font-size:13px;
}
.text-user{
    font-size:12px;
    font-weight: 500;
    margin-right: 15px;
}

.icon-link{
    font-size:15px !important;
    margin:0 0 0 8px
    // width: 20px;
    
}


.link-pointer{
    cursor:pointer;
}

.work-div{
    flex:1;
    background-color:white;
    border-radius: 15px;
    flex-direction: column;
    display:flex;
    align-items: center;
    // padding: 15px;
    cursor:pointer;
}
.badge-text-lowBadge{
    background-color:#f1c811;
    color:white;
    padding: 5px 15px 5px 15px;
    border-radius:15px;
}
.badge-text-mediumBadge{
    background-color:#f17d11;
    color:white;
    padding: 5px 15px 5px 15px;
    border-radius:15px;
}
.badge-text-highBadge{
    background-color:#f12711;
    color:white;
    padding: 5px 15px 5px 15px;
    border-radius:15px;
}
.badge-text-positive{
    background-color:#20a74c;
    color:white;
    padding: 5px 15px 5px 15px;
    border-radius:15px;
}

.reply-div{
    display: flex;
    flex-direction: column;
    background: #015cb5;
    width: 100%;
    color: white;
    padding: 5px 15px 5px 15px;
    border-radius: 15px;
    border-left: 5px solid #00478a;
    cursor: pointer;
    &:hover {
     text-decoration: none;  
     color: white;
     }
}

.div-time{
    display: flex;
    justify-content: end;
    margin-top: 5px;
}

.div-channel-list{
    padding: 15px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    &:hover{
        background:#e9eef4
     }
}
.div-channel-list-activity{
    padding: 15px;
    display: flex;
    cursor: pointer;
    align-items: center;
 background:#e9eef4;
 justify-content: space-between;
    &:hover{
        background:#e4e9f0
     }
}

.div-data-list{
    margin-left: 15px;
    display: flex;
    flex-direction: column;
}

.last-message{
    font-size:11px; 
    font-weight:400;
    color:#999999
}
.last-message-viewer{
    font-size:11px; 
    font-weight:400;
    color:$v2-secundary-color;
}
.spinner-chat{
    position: absolute;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    margin-top: 15px;
    height: 100vh;
    align-items: center;
    flex-direction: column;
}

.icon-down-white{
cursor: pointer;
color:white;
// margin-left: 15px;
}

.icon-down-black{
    // margin-left: 15px;
    cursor: pointer;
    }

.icon-div{
    display: flex;
    justify-content: end;
    margin-bottom: 5px;
    margin-left: 15px;
}

.div-respondent{
flex:1;
// padding: 15px 40px;
}

.div-row-respondent{
display: flex;
flex-direction: column;
background:#f0f3f7;
border-radius: 15px;
border-left: 5px solid #00478a;
padding: 15px;
margin: 15px;
}

.div-icon-close{
    display: flex;
    justify-content: end;
    margin:5px 10px;
    cursor: pointer;
}

.date-message{
    display: flex;
    justify-content:center;
    margin-top: 15px;
}

.text-date{
   
    font-size: 12px;
    color: $v2-primary-color;
    background-color: #f8f8fa;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
    font-size: 12px;
    // color: gray;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 4px 20px rgba(50, 50, 71, 0.08);
}

.not-chat{
    flex: 1;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
}
.list-chats{
    height: 300px;
    overflow-y: scroll;
}

.chat-header{
    width: 100%;
    background-color:white;
    padding: 15px;
    border-bottom: 1px solid #d0dae5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-filter{
    font-size: 20px;
    margin-left: 15px;
    cursor: pointer;
    color: #2A3F92 !important;

}
.icon-close{
    font-size: 20px;
    margin-left: 15px;
    cursor: pointer;
    color: #ae2525 !important;
}

.div-filter{
display: flex;
align-items: center;
height: 50px;
}

.v2-daterangepicker {
    margin-bottom: 0px;

 
}

.a-reply{
    cursor: pointer;
    text-decoration: none;
}

.div-scroll-respondent{
    padding: 10px 16px;
    display: flex;
    justify-content: flex-end;
    animation-name: example;
    animation-duration: 8s
  
}

@keyframes example {
    from {background-color:#cfd1d3}
    to {background-color: #f0f3f7;}
  }

.icon-more{
    display: flex;
    justify-content: flex-end;
    
}

.icon-add{
    font-size: 20px;
    margin-left: 5px;
    cursor: pointer;
    color: #2A3F92 !important;
}

.last-message-bold{
    font-size:11px; 
    font-weight:bold;
    color:#999999;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.description{
    display: none;
    justify-content: end;
}
.div-channel-list-activity:hover .description{
    display: block;
    
}
.div-channel-list:hover .description{
    display: block;
    
}

.div-row-itens{
    display: flex;
}
.edit-picker{
    position: absolute;
    background: white;
    border-radius: 5px;
      margin: 5px;
      left: 52px;
    width: 200px;
    margin-top: 30px;
      
      box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.09);
    .li{
    list-style: none;
    }
}
.teste:hover .description{
    display: block;
    
}
.icon-isUnread{

color:$v2-secundary-color;
margin-left: 5px;
    // right: 13px;
    // top: 80px;
    // position: relative;
}
.edit-picker-sender{
        position: absolute;
        background: white;
        border-radius: 5px;
          margin: 5px;
          left: 83px;
          box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.09);
        .li{
        list-style: none;
        }
    
}

.icon-forward{
    color: white;
}

.text-forward{
    color: white;
    font-size:12px;
    margin-left:5px
}
.div-primary{
    border-right: 1px solid #d0dae5;
    max-width: 300px;
    overflow: hidden;
}
.div-icon-arrow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.name-send{
    margin-top: 15px;
}

.text-max{
    max-width: 200px;
}