.pdf-modal {
    max-width: 900px;

    canvas {
        width: 100% !important;
        height: inherit !important;
        border: 1px solid $dark-gray-opacity;
    }

    .react-pdf__Page__textContent {
        position: absolute;
        top: -999999px !important;
    }

    .cropper-canvas,
    .cropper-crop-box {
        // top: -77px;
        // transform: none !important;
    }

    .tile {
        width: initial !important;
    }

    .confirm-delete {
        font-size: 9px;
    }

    .cut-button {
        position: absolute;
        bottom: -60px;
        right: 0px;
    }

    .card {
        &.card-wizard {
            box-shadow: none;
        }
    }

    .pdf-container {
        width: 100%;
        position: relative;
        margin-bottom: 60px;
    }

    .modal-body {
        padding: 10px !important;
    }

    .editor {
        position: relative;
        ul {
            li:nth-child(1),
            li:nth-child(2) {
                display: none;
            }

            li {
                position: absolute;
                bottom: -60px;
                right: 0px;
                list-style: none;
                button {
                    background-color: $v2-info-color;
                    color: #ffffff;
                    border-width: 2px;
                    font-weight: 600;
                    line-height: 1.35em;
                    text-transform: uppercase;
                    border: none;
                    margin: 10px 1px;
                    border-radius: 3px;
                    padding: 11px 22px;
                    transition: all 150ms linear;
                    text-indent: -9999px;
                    line-height: 0;

                    &:after {
                        content: "Cortar";
                        text-indent: 0;
                        display: block;
                        line-height: initial;
                    }
                }
            }
        }
    }

    .nav {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e5e9f0;
        border-radius: 25px;
        margin-top: 5px;
        margin-bottom: 5px;

        p {
            margin-bottom: 0px;
            margin-left: 10px;
            margin-right: 10px;
        }

        .nav-item {
            .nav-link {
                color: $v2-default-color !important;
                font-family: "Manrope";
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    .moving-tab {
        background-color: $v2-secundary-color;
        border-radius: 25px;
        font-family: "Manrope";
        font-size: 14px;
        font-weight: 600;
    }

    .card-footer {
        padding: 0.75rem 0rem;
        button {
            font-family: "Manrope";
            font-size: 14px;
            font-weight: 600;
        }
    }

    .pdf-container {
        button {
            float: right;
        }

        li {
            button {
                background-color: transparent !important;
                // border-color: $v2-secundary-color !important;
                // border-width: 2px;
                border: 1px solid $v2-secundary-color !important;
                color: $v2-secundary-color !important;
                border-radius: 10px !important;
                height: 50px;
                margin-bottom: 5px !important;
                font-family: "Manrope";
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}
