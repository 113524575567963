.modal-edit-image {
    max-width: 900px;

    .modal-body {
        padding: 0
    }

    .tui-image-editor-container {
        .tui-image-editor-header {
            display: none;
        }

        ul.tui-colorpicker-clearfix {
            margin-bottom: 10px;
            height: 40px;
            li {
                width: 20px;
                float: left;
                list-style: none;
            }
        }

        .tui-colorpicker-palette-button {
            width: 15px;
            height: 15px;
        }
    }

    @media screen and (max-width: 576px){
        max-width: 100%;
    }
}