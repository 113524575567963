.quill {
  .rdw-editor-toolbar {
    padding-bottom: 5px;
    background-color: $light-gray;
  }

  .ql-editor {
    padding: 10px;
    min-height: 100px;
    max-height: 300px;
  }

  .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .ql-snow {
    border: 1px solid #dddddd;
  }
}

.fr-popup {
  z-index: 999999999 !important;
}

.editor-container {
  position: relative;

  .save-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: green;
    font-size: 20px;
  }

  &.isEditing {
    .ql-editor {
      max-height: 600px;
    }

    .sun-editor {
      .se-wrapper {
        .se-wrapper-wysiwyg {
          line-height: normal;

          p {
            margin: 0px 0px 2px;
          }
        }

        .se-wrapper-inner:focus {
          height: max-content !important;
          min-height: 200px;
          margin-bottom: 35px;
        }
      }
    }

    .fr-box.fr-basic {
      .fr-wrapper {
        // height: 200px !important;
        // min-height: 200px !important;
        overflow: overlay !important;
        // padding: 10px !important;
      }
    }

    &.noAddButton {
      .fr-quick-insert {
        display: none;
      }
    }
  }

  .editor-edit {
    // position: absolute;
    // bottom: -5px;
    // right: 5px;
    // z-index: 999999;
    float: right;
  }
}

.safari {
  iframe,
  .fr-wrapper,
  .fr-box {
    height: 100% !important;
  }
}
