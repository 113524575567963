.toastDiv {
    min-width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;

    .divTitle {
        flex: 10;
        text-align: center;
        padding: 10px;
        margin-left: 60px;
        position: relative;
        //display: flex;

        .icon {
            right: 10px;
            justify-content: center;
            position: relative;
        }
    }

    .closedButton {
        background: transparent;
        color: #fff;
        flex: 1;
        position: relative;
        border: none;
        outline: none;
        float: right;
    }
}
