img{
    max-width: 100%;
    border-radius: $border-radius-small;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}
#ReactGridGallery {
    display: flex;
    flex-wrap: wrap;
}

.modal-content {
    #ReactGridGallery {
        max-height: 450px;
        overflow-y: auto;
    }
}

#react-confirm-alert {
    z-index: 99999999;
    position: fixed;
}