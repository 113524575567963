.add-files {
    background: #f3f7fa;
    border: 1px dashed $v2-icon-blue-color;
    box-sizing: border-box;
    border-radius: 10px;
    height: 100%;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: 550px;
    overflow: auto;
    text-transform: none !important;

    h4 {
        margin-top: 0px;
        margin-bottom: 12px;
    }

    &.inspection {
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;

            h4 {
                font-weight: 300;
                font-size: 18px;
            }

            a {
                margin-left: 30px;
                margin-bottom: 10px;

                @include mobile {
                    margin-left: 0px;
                }
            }

            > div {
                width: 32.4%;

                @include mobile {
                    width: 100%;
                }

                label {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            > div:nth-child(3n + 2) {
                margin-inline: 10px;

                @include mobile {
                    margin-inline: 0px;
                }
            }
        }
    }

    &.large-dropzone {
        min-height: 100px !important;

        > div {
            display: flex;
            flex-direction: column;
            // height: 75px;

            h4 {
                font-size: 2.3em;
            }

            a {
                font-size: 20px;
            }
        }
    }
}

.uploaded-file {
    background: #ffffff;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    cursor: pointer;

    .img {
        width: 44px;
        height: 44px;
        border-radius: 10px;
        background-size: 44px 44px !important;
    }
    label {
        margin-left: 12px;
        flex: 1;
        cursor: pointer;
        color: $v2-default-color;
        margin-bottom: 0px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.v2-category {
    margin-top: 10px;
    position: relative;

    .controls {
        position: absolute;
        top: 12px;
        right: 10px;

        svg {
            cursor: pointer;
            color: $v2-secundary-color;
        }
    }
}

.v2-categories {
    .v2-button-text {
        margin-top: 10px;
        margin-bottom: 10px;
        min-width: 205px;
    }
}

.v2-react-dropzone {
    outline: none !important;
}
