.inspection-model {
    .div-question {
        flex-direction: row;
        display: flex;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.09);
        border-radius: 10px;
        align-items: center;
        padding-inline: 15px;
        min-height: 50px;
        font-family: "Manrope";
        margin-bottom: 5px;

        .details-description {
            color: #5f5f5f;
            flex: 1;
        }

        .icon-question {
            color: $v2-secundary-color;
            font-size: 20px;
        }
    }

    .v2-add-icon {
        padding-left: 0px;
        padding-right: 0px;
    }

    .items-col {
        @include mobile {
            flex-direction: column;
        }

        .col {
            @include mobile {
                margin-bottom: 10px;
            }
        }
    }

    .inspection-card {
        padding-inline: 30px;
    }
}
