.users-screen,
.skills-screen {
    .row-user {
        display: flex;
        align-items: center;
        // color: $v2-primary-color;

        .avatar {
            margin-left: 15px;
            margin-bottom: 0px;

            img {
                width: 40px;
                height: 40px;
            }
        }
        .avatar-mini {
            margin-left: 10px;

            img {
                width: 25px;
                height: 25px;
            }
        }

        .details {
            display: flex;
            flex-direction: column;

            &.schedule-list {
                color: $v2-primary-color;
                white-space: nowrap;

                &.badge-status {
                    margin-right: 45px;
                }
            }
        }

        &.padding-1 {
            padding-left: 64px;
        }

        &.padding-2 {
            padding-left: 75px;
        }

        &.label-primary {
            color: $v2-primary-color;
        }
    }

    .badge {
        cursor: pointer;
        margin-bottom: 0px;

        &.admin {
            background-color: $purple-color;
        }

        &.member {
            background-color: $default-color;
        }
    }
}
