.card-lib-details {
  display: block;
  margin: 0 auto;
  z-index: 2;
  max-width: 960px;

  .save-button {
    float: right;
  }

  .section-button {
    padding: 0.2rem;
    float: right;
    margin: 2px;
    display: flex;

    i {
      margin: 0px;
    }
  }

  .section-span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 32px;
    margin-right: 75px;
  }

  .filter-title {
    margin-bottom: 6px;
    display: block;
    color: $default-color;
  }

  .react-select__control {
    font-size: 14px;

    .react-select__placeholder {
      font-size: 14px;
      color: $default-color;
      text-transform: initial;
    }

    .react-select__single-value {
      font-size: 14px;
    }
  }

  .crop-label {
    // float: right;	
  }

  hr {
    margin-top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 0px solid rgba(0, 0, 0, 0.1);
  }

  .title {
    color: $dark-color !important
  }

  .logo-card {
    margin: 0 auto;
    display: block;
    margin-bottom: 35px;
    margin-top: 25px;
  }

  .category {
    position: relative;
    border-radius: 10px;
    margin-bottom: 20px;


    .drop-file {
      width: 116px;
      margin: 0 auto;
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &:not(.editable) {

      .ask {
        .title {
          text-transform: initial;
        }
      }

      .row-check {
        >span {
          display: flex !important;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }

    &.editable {
      border: 1px dashed $medium-gray;
      padding: 5px;

      .react-select__placeholder {
        font-size: 14px;
        color: $default-color;
        text-transform: initial;
      }

      .react-select__single-value {
        font-size: 14px;
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: center;

        &.item-col {
          flex-direction: column;

          .form-group {
            width: 100%;
          }
        }
      }

      .title-group-input {
        width: calc(100% - 30px);
        margin: 0 auto;
      }

      .select-form-group {
        display: flex;
        margin-top: 10px;

        >div {
          padding: 0px;
        }
      }

      .group-analysis {
        .react-select__multi-value__label {
          max-width: 100px;
        }

        >div {
          display: flex;
          align-items: center;
          justify-content: center;

          .react-select {
            width: 100%;
          }

          &:last-child {
            padding-left: 10px;
          }
        }
      }

      .ask {
        border: 1px dashed $medium-gray;
        background-color: #ebebeb;
        padding: 5px;

        .option-container {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &.label {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
          }
        }

        .scale-icons,
        .row-check {
          .item:first-child {
            span {
              display: flex !important;
              align-items: center;
              flex-wrap: wrap;
              justify-content: center;
            }
          }
        }

        .react-select__control {
          background-color: white;
          font-size: 12px;
        }

        .row-description {
          width: 100%;
          margin: 0 auto;
        }

        .controls-ask {
          display: none;
        }

        &:hover {
          .controls-ask {
            display: flex;
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: 9999;

            button {
              margin: 0px;
              height: 25px;
              width: 25px;
              padding: 0px;
              border-radius: 0px;

              svg {
                color: white;
                font-size: 16px;
              }
            }
          }
        }
      }

    }

    .controls {
      display: none;
    }

    &:hover {
      .controls {
        display: flex;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 9999;

        button {
          margin: 0px;
          height: 25px;
          width: 25px;
          padding: 0px;
          border-radius: 0px;

          &:last-child {
            border-top-right-radius: 10px !important;
          }

          svg {
            color: white;
            font-size: 16px;
          }
        }
      }
    }



    h4 {
      margin-top: 7px;
      color: $default-color;
    }

    .footer-options {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        border-style: dashed !important;
        padding: 5px 10px;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  .header-col {
    display: flex;

    .card-title {
      width: 100%;
    }

    button {
      float: right;
    }
  }
  
  .lib-link {
    display: flex;
    justify-content: space-between;

    .link {
      // width: 400px;
      width: 83%;
      height: 40px;

      div {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 10px;
        border: 1px solid #DDDDDD;
        border-radius: 4px;

        a {
          color: #66615b;
        }
      }
    }

    i {
      margin: 0 auto;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}

.spinner-details {
  margin: 0 auto;
  margin-top: 100px;
}

.come-back {
  background-color: transparent !important;
  color: $default-color;
  padding-left: 0;
  padding-right: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    color: $default-color !important;

    &:active {
      background-color: transparent !important;
      color: $default-color !important;
    }

    &:hover {
      background-color: transparent !important;
      color: $default-color !important;
    }
  }
}

.confirm-delete {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  z-index: 999;

  svg,
  h4 {
    color: white !important;
    text-transform: initial;
  }
}