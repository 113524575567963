.v2-details {
    color: $v2-primary-color;
    h5 {
        margin-bottom: 5px;
    }

    p {
        color: $v2-default-color;
        margin-bottom: 0px;
    }
}

.badge-text {
    // cursor: pointer;
    color: $v2-default-color !important;
    margin-right: 10px;
}

.user-label-title {
    margin-right: 20px;
    margin-bottom: 0px;
}

.row-user {
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar {
    height: 48px;
    width: 48px;
    overflow: hidden;
    border-radius: 48px;
}
