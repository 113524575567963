.skills {
    .badge {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 3px;

        &.lib {
            background-color: $primary-color;
        }
    }

    .icon-bookmark {
        color: $primary-color;

        // &.two {
        //     margin-left: 20px;
        // }

        &.three {
            color: $v2-icon-blue-color;
            // margin-left: 50px;
        }
    }
    .details {
        font-size: 14px;
    }
}
