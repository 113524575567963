$image-size: 200px;

.drop-zone-header {
  display: flex;

  @include mobile {
    flex-wrap: wrap;
    text-align: center;
  }

  .button-left {
    width: 100%;
    display: flex;

    // button {
    //   height: 28px;
    //   padding: 0px;
    //   padding-inline: 5px;
    // }
  }
}

.react-dropzone {
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px !important;
  text-transform: initial !important;
  padding-top: 10px;
  padding-bottom: 10px;
  // height: 60px;
  cursor: pointer;

  &.hidden {
    border: 0px solid transparent;
    box-shadow: none !important;
    outline: none !important;
  }

  &.disabled {
    cursor: auto;
  }

  p {
    margin-bottom: 0;
  }

  .icon-label-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.file-container {
  margin: 0 auto;
  width: $image-size;
  height: $image-size;
  margin-bottom: 20px;

  &.mini {
    width: 135px !important;
    height: 135px !important;

    img {
      // position: relative;
      // list-style: none;
      width: $image-size;
      border-radius: $image-size;
      max-height: 130px;
    
      // height: $image-size;
    }
  }

  .react-dropzone,
  li,
  img {
    position: relative;
    list-style: none;
    width: $image-size;
    height: $image-size;
    // border-radius: $image-size;
    // border-radius: 10px;
  }

  li {
    border: 1px solid #dddddd;
  }
}

.file-simple {
  padding: 20px;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 30px;
  }
}

.button-right {
  text-align: right;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  // height: 48px;

  @include mobile {
    justify-content: center;
  }

  .button-group {
    font-size: 11px;

    &:hover {
      background-color: $default-bg !important;
    }

    &:focus {
      // background-color: $default-states-color !important;
    }
  }

  .select-buttons {
    display: flex;

    .button-select {
      font-size: 11px;
      width: max-content;
      padding: 0.25rem 0.5rem;
    }

    .button-delete {
      padding: 0px;
      // padding-left: 3px;
      border-radius: 8px !important;
      background-color: transparent;
      border: 1px solid $v2-secundary-color;
      padding-inline: 3px;

      svg {
        color: $v2-secundary-color;
        font-size: 20px;
      }

      &:hover,
      &:active {
        background-color: transparent !important;
        border-color: $v2-secundary-color !important;
        opacity: 0.5;
      }
    }

    .btn-outline-secondary {
      border-color: $v2-secundary-color !important;
      background-color: transparent !important;
      color: $v2-secundary-color !important;
    }

    .v2-primary-btn {
      white-space: nowrap;
    }
  }
}

.button-delete-gallery {
  z-index: 999;
  pointer-events: auto;
  position: absolute;
  top: 8px;
  right: 8px;
  margin: 0px;
  height: 23px;
  width: 23px;
  padding: 0px;
  border-radius: 20px;

  button {
    padding: 2px !important;
    font-size: 1.1rem;
  }
}

.button-edit-gallery {
  z-index: 999;
  margin: 0px;
  pointer-events: auto;
  height: 23px;
  width: 23px;
  position: absolute;
  top: 8px;
  right: 38px;
  border-radius: 20px;

  button {
    padding: 2px !important;
    font-size: 1.1rem;
  }

  &.vertical {
    top: 38px;
    right: 8px;
  }
}

.button-header {
  /* display: flex;
  justify-content: center;
  padding-top: 30px;
  margin-bottom: 15px; */
  display: flex;
  justify-content: center;
  padding: 2px;
  background-color: #e5e9f0;
  border-radius: 50px;
  flex: 1;
  margin: 30px 30px 10px 30px;

  @include mobile {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .all-buttons {
    flex: 1;
    width: 100%;
  }

  .button-group {
    flex: 1;
    background-color: transparent !important;
    color: $default-bg;
    margin: 8px;
    margin-inline: 10px;
    border-radius: 15px !important;
    height: 32px;
    font-family: "Manrope";
    // font-style: normal;
    // font-weight: 600;
    font-size: 14px;
    // border: 2px solid $default-bg;

    &.active {
      background-color: $primary-color !important;
      // border: 2px solid $default-bg;
    }

    &:hover {
      background-color: $primary-color !important;
    }

    &:focus {
      background-color: $primary-color !important;
    }
  }
}

.work-footer {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  margin-bottom: 50px;
  // background-color: #f4f3ef;
  // background-color: $primary-color;
  // border-top: 1px solid #ddd;

  .work-footer-button {
    display: flex;
    align-items: center;
    margin: 0;
    align-self: center;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    background: transparent;
    color: $v2-secundary-color;
    border: 1px solid $v2-secundary-color;
    border-radius: 20px;
    background-color: white;

    i {
      font-size: 20px;
      margin: 0;
      padding-bottom: 3px;
    }

    span {
      margin-left: 5px;
    }
  }

  .work-footer-badge {
    background-color: $opacity-gray-8;
    color: $font-color;
  }

  .work-footer-badgeOn {
    background-color: $info-color;
    color: $fill-font-color;
  }

  ul {
    flex-wrap: nowrap;
    overflow-y: hidden;
    width: 100%;
    // font-size: 20px;
    margin: 0;

    li {
      > div {
        height: 26px;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline-start: 10px;
      width: auto !important;
      height: 50px !important;

      span {
        display: initial;
        vertical-align: bottom !important;
      }
    }
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $primary-color;
  background-color: $primary-color;
}

.modal-button {
  margin: 0px;
  margin-right: 10px;
}

.scale-group {
  .scale-button {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0px;
    background-color: transparent !important;

    label {
      margin: 0px;
      cursor: pointer;
      color: $primary-color !important;
    }

    &:hover,
    &:focus {
      // background-color: transparent !important; // $default-states-color !important;
      label {
        color: $primary-color !important;
      }
    }

    &.positive {
      border-color: #20a74c !important;
      label {
        color: #20a74c !important;
      }
    }

    &.active {
      //  background-color: $primary-color !important; // $default-states-color !important;

      &.positive {
        background-color: #20a74c !important;
      }

      &.low {
        background-color: #f5af19 !important;
      }

      &.medium {
        background-color: #ef6615 !important;
      }

      &.high {
        background-color: #ef6d2e !important;
      }

      label {
        color: white !important;
      }
    }
  }

  /* background: linear-gradient(to right, #f5af19, #ef6d2e);
  width: 100%;
  height: 50px;

  .scale-button {
    background-color: transparent;
    height: 50px !important;
    margin: 0px;
    padding: 0px;
    border-right-color: white !important;
    border-right-width: 1px;
    border-right-style: solid;

    &:nth-last-child() {
      border-right-width: 0px;
    }

    &:hover {
      background: radial-gradient(circle, rgba(255, 255, 255, 0.6), transparent, transparent) !important;
    }

    &:focus,
    &.active {
      background: radial-gradient(circle, rgb(255, 255, 255), transparent, transparent) !important;

      .scale-label {
        margin: 0px;
        color: #ef6d2e;
      }
    }

    .scale-label {
      margin: 0px;
      color: white;
    }
  } */
}

@media screen and (max-width: 576px) {
  .modal-dialog {
    max-width: 100% !important;
    width: 96% !important;
    padding-right: 0px;

    .moving-tab {
      width: 50% !important;
    }

    .custom-table .custom-table-toolbar > div {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
    }
  }
}

.details-wizard {
  .details-title {
    margin-top: 5px;
    color: black;
  }

  .title-center {
    text-align: center;
    font-size: $font-size-h4;
  }

  .subtitle-center {
    text-align: center;
    font-size: 1.2em;
    color: $font-color;
    margin-bottom: 10px;
  }

  .card {
    box-shadow: none !important;
  }

  .tab-content {
    min-height: 0px;
  }

  .react-select .react-select__control .react-select__value-container {
    max-width: 250px; //aqui
  }
}
