.v2-logo-ike {
    display: block;
    margin: 0 auto;
}

.v2-separator {
    height: 50px;
    opacity: 0.2;
    border-bottom: 1px solid #677DA3;
    margin-bottom: 50px;
}

.v2-text-recovery {
    margin-top: 15px;
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

    .container {
        width: 100%;

        @include tablet {
            width:100%
        }
    
        @include mobile {
            width:80%
        }
    }
}

.img-login-container {
    z-index: 999;
    position: relative;
    min-width: 30%;
    max-width: 720px;
    height: 100vh;
    background-size: cover !important;
    display: none;

    @include desktop {
        display: block;
    }
}

.card-login {

    > div:first-child {
        text-align: center;
    }
    .link-text {
        color: $v2-secundary-color;
    }
}