.preview-container {
    margin: 0 auto;
    display: block;
    width: 100%;
    max-height: calc(100vh - 300px);
    // overflow-y: auto;
    // overflow-x: hidden;
    margin-top: 30px;

    .title-dropzone {
        margin-top: 30px;
        margin-bottom: 30px;
        font-weight: 450;
    }

    .tile-grid {
        margin-top: 30px !important;
    }

    .img-dropzone {
        // margin: 10px auto;
    }

    .tile {
        text-align: center;
        margin-bottom: 20px;
        height: 100% !important;

        .file-item {
            background-color: black;
            width: 300px;
            height: 300px;
            border-radius: 5px;
            position: relative;
            margin: 0 auto;
            object-fit: scale-down;

            .check-select {
                position: absolute;
                top: -4px;
                left: 0px;
                outline: none !important;

                .custom-control-input:checked ~ .custom-control-label::before {
                    border-color: $primary-color;
                    background-color: $primary-color;
                }

                input {
                    height: 30px;
                    width: 30px;
                    left: 0;
                    top: 4px;
                    cursor: pointer;
                    z-index: 3;

                    outline: none !important;
                }

                label {
                    outline: none !important;
                    &::after,
                    &::before {
                        height: 30px;
                        width: 30px;
                        cursor: pointer;
                        outline: none !important;
                    }
                }
            }
        }

        .file-item-div {
            width: 292px;
        }

        .file-item-div,
        .gallery-div {
            .button-edit-image {
                display: none;
            }

            &:hover {
                .button-edit-image {
                    position: absolute !important;
                    display: block;
                    right: 20px;
                    top: -10px;
                    z-index: 999;
                    height: 25px;
                    width: 25px;
                }
            }

            .button-hide-image {
                display: none;
            }

            &:hover {
                .button-hide-image {
                    position: absolute !important;
                    display: block;
                    right: 50px;
                    top: -10px;
                    z-index: 999;
                    height: 25px;
                    width: 25px;
                    background-color: $dark-gray !important;

                    &.hiding {
                        background-color: $default-bg !important;
                    }

                    i {
                        font-size: 14px;
                    }
                }
            }
        }

        .file-item-bordered {
            width: 292px;
            border: 1px solid $medium-gray;
            padding-left: 5px;
            padding-right: 5px;
        }

        .column {
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 25px;
        }

        img {
            object-fit: initial;
            padding: 1px;
            border: 1px solid $medium-gray;
        }

        .truncated {
            max-width: 110px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block !important;
        }
    }

    .tile-bar {
        border-radius: 0px 0px 5px 5px;
        height: 35px;
    }
}

.image-gallery-slides {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.gallery-div {
    background-color: black;
    .file-item {
        height: calc(100vh - 340px);
        object-fit: scale-down;
    }

    .button-edit-image {
        display: none;
    }

    &:hover {
        .button-edit-image {
            position: absolute !important;
            display: block;
            right: 20px;
            top: -10px;
            z-index: 999;
            height: 25px;
            width: 25px;
        }
    }

    .check-select {
        position: absolute;
        top: -5px;
        left: -1px;
        outline: none !important;

        input {
            height: 30px;
            width: 30px;
            left: 0;
            top: 4px;
            cursor: pointer;
            z-index: 99999;

            outline: none !important;
        }

        label {
            outline: none !important;
            &::after,
            &::before {
                height: 30px;
                width: 30px;
                cursor: pointer;
                outline: none !important;
            }
        }
    }
}
