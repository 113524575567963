.company-works-list {
  padding-top: 30px;
}

.works-toolbar {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .button-add {
    display: block;
  }

  .button-add-fixed {
    display: none;
    width: 60px !important;
    height: 60px !important;
    z-index: 2000;
  }

  @media screen and (max-width: 576px) {
    .button-add {
      display: none;
    }

    .button-add-fixed {
      display: block;
      position: fixed !important;
      bottom: 5px;
      right: 20px;
    }

    .edit-custom-row {
      > input:first-of-type {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 5px;
      }
    }

    .actions {
      display: none;
    }
  }

  .input-form-group {
    position: relative;
    width: 210px;
  }

  .react-select {
    margin-right: 10px;
    width: 210px;
    display: flex !important;
    align-items: center;

    .react-select__placeholder {
      font-size: 14px !important;
      font: inherit;
    }

    .react-select__control,
    > input {
      background-color: white !important;
      height: 36px;
      min-height: 36px;
      border: 1px solid #ddd !important;

      > div {
        margin: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 35px;
        min-height: 35px;

        > div {
          margin: 0px;
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }
    }

    input {
      cursor: pointer;
      font-size: 14px;
      color: #9a9a9a;
    }

    .react-select__menu {
      z-index: 99999;
    }

    div,
    input {
      &:focus {
        outline: none !important;
      }
    }

    .option {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block !important;
    }
  }

  input {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 35px;
    padding-left: 30px !important;
    font-size: 14px;
    font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
    font-stretch: 100%;
    font-style: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    -webkit-font-variant-ligatures: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 16.625px;

    &:before {
      border: none !important;
    }

    &:after {
      border: none !important;
    }

    .material-icons {
      color: #4d4d4d;
    }
  }

  .icon-search {
    position: absolute;
    left: 5px;
    top: 5px;
  }

  .button-add {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 170px;
    padding: 6px 15px;
    margin-left: 10px;
  }

  .filter-works {
    margin-right: 10px;

    .picky {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-left: 10px;

      > button {
        background-color: white;
        border: 1px solid #dddddd;
        border-radius: 4px;
        height: 35px;
        width: 180px;
        padding-left: 10px;
        font-size: 14px;
        font-family: Montserrat, "Helvetica Neue", Arial, sans-serif;
        font-stretch: 100%;
        font-style: normal;
        font-variant-caps: normal;
        font-variant-east-asian: normal;
        font-variant-ligatures: normal;
        font-variant-numeric: normal;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 16.625px;

        span {
          color: #9a9a9a !important;
        }

        &::after {
          color: #4d4d4d;
        }

        .picky__placeholder {
          padding-top: 3px;
          max-width: 85%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block !important;
        }
      }

      .picky__filter__input {
        padding-left: 10px !important;
      }

      .option {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex !important;
        align-items: center;
        height: 40px;
      }
    }

    :focus {
      outline: none !important;
    }
  }
}

.works-company-empty {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  color: $dark-gray-opacity;

  h5 {
    text-align: center;
    margin: 0 auto;
  }
}

.works-progress {
  text-align: center;
}

.works-company {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid $light-gray;

  .btn-toggle {
    background-color: transparent !important;
    color: $primary-color !important;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .company-image {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-right: 15px;
  }

  .card-title {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 30px;
  }

  hr {
    height: 1px;
    width: 100%;
    background-color: $dark-gray;
  }
}

.card-works-details {
  margin: 0 auto;

  .react-select__placeholder,
  .react-select__single-value,
  .react-select__multi-value__label {
    color: #66615b !important;
    font-size: 14px !important;
  }

  .btn-delete-works {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $danger-color !important;
    width: 140px;

    i {
      font-size: 20px;
    }
  }

  .title-works {
    width: 100%;
  }

  .header-card-works {
    display: flex;
  }
}

.card-work {
  position: relative;
  cursor: pointer;

  .button-edit {
    display: none;
    position: absolute !important;
    right: 10px;
    top: 0px;
  }

  &:hover {
    .button-edit {
      display: block;
    }
  }

  .card-image {
    height: calc(100vw / 6);
    min-height: 150px;
    object-fit: cover;
  }
}
.card-select-group {
  margin-bottom: 20px !important;
}

.card-img-top {
  border-top-left-radius:14px !important;
  border-top-right-radius: 14px !important;
}

.modal-title {
  margin: 0px !important;
}

.list-margin {
  margin: 10px;

  &.bottom {
    margin-bottom: 3rem;
  }

  &.right {
    float: right;
    color: #797979;
    border-color: #797979 !important;
    margin-left: auto;

    &:hover {
      border-color: #797979 !important;
      background-color: #797979 !important;
    }
  }

  &.headerText {
    color: #4d4d4d;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-family: "Manrope";

    &.index {
      font-size: 18px;
      margin: 0px !important;
      color: #ef6615;
    }

    &.paste-button {
      float: right;
    }

    &.float-button {
      position: absolute;
      right: -40px;
      top: 0px;
      border-radius: 100%;
      width: 35px;
      height: 35px;

      i {
        right: 9px;
        position: relative;
      }
    }

    button {
      margin-left: 10px;
    }

    button:last-child {
      margin-left: 10px;
    }

    .badge {
      color: white;
      border-radius: 90px;
      font-size: 12px;
      line-height: 16px;
      padding: 5px 12px 5px;
    }
  }
}

.border-color {
  width: 20px;
  height: 20px;
  align-items: center;
  border: 2px solid grey !important;

  &.white {
    margin-right: 2px;
    background-color: white !important;
  }

  &.black {
    background-color: black !important;
  }

  &.active {
    border-color: $primary-color !important;
  }
}

.div-image {
  overflow: hidden;
  max-width: 100%;
  position: relative;

  .rigth {
    top: 10px;
    right: -20px;
  }

  > div {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .button-edit-image {
    display: none;
    position: absolute !important;
    top: 1px;
    right: 45px;
  }

  .delete-controls {
    top: 10px !important;
    right: 10px !important;

    button {
      height: 1.875rem !important;
      min-width: 1.875rem !important;
      width: 1.875rem !important;
      border-top-right-radius: 3px !important;
      border-radius: 3px !important;
    }
  }

  &:hover {
    .button-edit-image {
      display: block;
    }
  }

  .list-image {
    margin-bottom: 0.75rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.image-grid {
  width: 100px;
  height: 100px;
  margin: 10px;
  background-color: lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: bold;
  color: white;
}

.report-header {
  cursor: pointer;
}

.report-group {
  margin-bottom: 5px;

  .add-image-button {
    margin-left: auto;
  }
}

.div-filter {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  justify-content: flex-end;

  .work-filter {
    max-width: 200px;
    flex: 1;
    margin-left: 10px;
    margin-bottom: 0.75rem;
    border: 1px solid #dddddd;
    border-radius: 4px;
  }

  .button-filter {
    height: 38px;
    margin: 0px;
    margin-left: 10px;
  }
}

.empty-report {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  display: block;
  font-family: "Manrope";
  font-weight: 600;
  padding-top: 0px;
  padding: 15px;

  .empty-icon {
    color: $v2-primary-color;
    position: relative;
    top: 20px;
    padding-right: 10px;
    font-size: 60px;
    margin-bottom: 30px;
  }
}

.empty-modal-pdf {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-family: "Manrope";
  font-weight: 600;
  height: 80px;

  span {
    margin-top: 25px;
  }
}

.empty-xlsx {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-family: "Manrope";
  font-weight: 600;
  margin-top: 25%;

  span {
    margin-top: 25px;
  }
}

.card-report {
  padding: 15px;
  text-align: center;
}

.list-report {
  margin-inline: 30px;

  @include mobile {
    margin-inline: 0px;
  }

  .filter-report {
    margin-left: 0px;
    margin-right: 0px;

    .col-sm-3 {
      margin-left: 10px;
      padding-left: 0px;
      padding-right: 0px;

      @include mobile {
        margin-bottom: 10px;
      }
    }

    .col-sm-12 {
      @include mobile {
        margin-bottom: 10px;
      }
    }
  }

  .v2-input-select,
  .v2-daterangepicker {
    margin-bottom: 0px;
  }
}

.filter-report {
  display: flex;
  padding-top: 15px;
  margin-bottom: 15px;
  align-items: center;
  justify-content: flex-end;

  // &.last {
  //   padding-top: 0px;
  //   margin-bottom: 40px;
  // }

  .has-filter {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  @include mobile {
        margin-bottom: 0px;
      }

  // .picky {
  //   > button {
  //     border: 1px solid #dddddd;
  //     border-radius: 4px;
  //     height: 35px;
  //     padding-left: 10px;
  //     line-height: 16.625px;

  //     span {
  //       color: #9a9a9a !important;
  //     }

  //     &::after {
  //       color: #4d4d4d;
  //     }
  //   }
  // }

  :focus {
    outline: none !important;
  }

  // .filter-ordination {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   .i {
  //     cursor: pointer;
  //   }

  /* .picky {
      >button {
        border: none;
        background-color: transparent;

        span {
          color: $black-color !important;
        }

        &::after {
          color: $black-color !important;
          right: 13px;
        }
      }
    } */
  // }

  .react-picky-not-select {
    input {
      cursor: default !important;
    }
  }

  // .react-select,
  // .react-bootstrap-daterangepicker-container {
  // margin-left: 10px;
  // width: 210px;
  // display: flex !important;
  // align-items: center;

  // .react-select__placeholder {
  //   font-size: 14px !important;
  //   font: inherit;
  // }

  // .react-select__control,
  // > input {
  //   background-color: white !important;
  //   height: 30px;
  //   min-height: 35px;
  //   border: 1px solid #ddd;
  // }

  // input {
  //   cursor: pointer !important;
  //   font-size: 14px;
  //   color: #9a9a9a;
  // }

  // .react-select__menu {
  //   z-index: 99999;
  // }

  // div,
  // input {
  //   &:focus {
  //     outline: none !important;
  //   }
  // }

  //   .option {
  //     width: 100%;
  //     white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     display: inline-block !important;
  //   }
  // }

  // .year-container {
  //   display: flex !important;
  //   align-items: center;

  //   .picker-panel {
  //     z-index: 99999;
  //   }

  //   .year-picker {
  //     border: 1px solid #dddddd;
  //     height: 35px;
  //     padding: 1px;
  //     margin-left: 10px;

  //     &.first {
  //       border-right-width: 0px;
  //       border-top-right-radius: 0px;
  //       border-bottom-right-radius: 0px;
  //     }

  //     &.last {
  //       border-top-left-radius: 0px;
  //       border-bottom-left-radius: 0px;
  //       margin-left: 0px;
  //       border-left-width: 0px;
  //     }
  //   }
  // }

  .button-alert {
    /* background-color: $white-color;
    color: $black-color;
    border: 1px solid $medium-gray !important;
    height: 35px;
    padding: 5px;
    width: 46.156px;

    i {
      margin: 0px;
    }

    &.active,
    &:hover,
    &:focus,
    &.active:hover,
    &.active:focus {
      background-color: $primary-color !important;
      color: $white-color !important;
    } */
    height: 50px;
    width: 50px;
    min-width: 50px;
    background-color: transparent !important;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Manrope";
    border: $v2-secundary-color 1px solid !important;
    color: $v2-secundary-color !important;
    padding: 5px;
    margin: 0px;
    margin-inline-start: 10px;

    // &.active {
    //   border: $v2-secundary-color 2px solid !important;
    //   background-color: $v2-secundary-color !important;
    //   color: white !important;
    // }
    &.active,
    &:active,
    &:hover,
    &:focus,
    &.active:hover,
    &.active:focus,
    &:active:hover {
      border: $v2-secundary-color 1px solid !important;
      color: $v2-secundary-color !important;
      background-color: transparent !important;
      box-shadow: none;
    }

    @include mobile {
      &:last-child {
        margin-right: 15px;
      }
    }
  }

  .button-filter {
    height: 35px;
    width: 100%;
  }
}

.datepicker-modal {
  width: 600px;
  max-width: initial;
}

.scale-work-button {
  &:focus,
  &:hover,
  .active {
    background-color: $primary-color !important;
  }
}

.upload-expense {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.picky__input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
