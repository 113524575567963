.lib-list {
  .badge {
    border-radius: 12px;
    padding: 2px;
    font-size: 10px;
    font-weight: normal;
    text-transform: capitalize;
    padding-right: 8px;
    padding-left: 8px;
  }

  .badge-tag {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .list-comment {
    float: none !important;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 15px;

    &.table-embeded {
      .list-margin {
        height: auto !important;
      }
    }
  }

  .row-user {
    // float: left !important; //testar
    justify-content: center !important;
    align-items: center !important;

    .form-check-input {
      position: initial;
      margin-top: 0;
      margin-left: 0;
    }
  }
}

.lib-images-container {
  display: flex;
  flex-wrap: wrap;

  &.col-img-1 {
    img {
      width: 100%;
    }
  }

  &.col-img-2 {
    img {
      width: 50%;
    }
  }

  &.col-img-3 {
    img {
      width: 33%;
    }
  }
}
