.table-button {
    background-color: $v2-light-color !important;
    padding: 9px !important;
    border-radius: 8px !important;

    &.margin {
        margin-right: 0px !important;
    }

    svg {
        color: $v2-secundary-color;
    }

    &.active {
        svg {
            color: $v2-primary-color;
        }

        &.iconBlue {
            svg {
                color: $v2-icon-blue-color;
            }
        }
    }

    &.blue {
        svg {
            color: $v2-primary-color;
        }
    }
}

.button-add {
    margin-left: 10px;
    margin-right: 0 !important;
    padding: 6px 12px;
    /* color: $primary-color !important;
    border: 2px solid $primary-color !important;
    background-color: transparent !important; */
    display: block;
    font-size: 12px;
}

.button-add-fixed {
    display: none;
    width: 60px !important;
    height: 60px !important;
    z-index: 1000;
}

.custom-table {
    @include mobile {
        &.marginZero {
            table {
                padding-left: 0px;
                padding-right: 0px;
                tr {
                    // overflow-wrap: break-word;
                    padding-left: 0px;
                    padding-right: 0px;

                    // td:last-child {
                    //     display: flex;
                    // }
                }
            }
        }
        &.wordWrap {
            td:last-child {
                display: flex;
                // justify-content: flex-end;
                // float: right;
                margin-bottom: 10px;
            }
        }
    }

    table {
        @include mobile {
            padding-left: 5px;
            padding-right: 5px;
        }
        tbody {
            @include mobile {
                overflow-wrap: anywhere;

                tr {
                    overflow-wrap: break-word;
                    padding-left: 5px;
                    padding-right: 5px;

                    // td:last-child {
                    //     display: flex;
                    // }
                }
            }
            tr:not([index]) {
                box-shadow: none !important;
                height: 100% !important;

                td {
                    // display: block;
                    width: 100vw;
                    display: table-cell !important;
                    padding: 10px;
                }
            }
        }
    }
}

tfoot {
    tr {
        box-shadow: none !important;
    }
}

.edit-row {
    width: 100%;
    tr {
        // display: flex;
        height: 72px;
        align-items: center;

        td {
            display: flex;
            height: 72px;
            align-items: center;
        }
    }
}

.check-box-input {

    width       : 18px;
    height      : 18px;
    margin-right: 10px;
    cursor: pointer;
}

.check-text {
    font-family: Inter, sans-serif;
    font-style : normal;
    font-weight: 500;
    font-size  : 14px;
    line-height: 24px;
    color      : white;
}

.div-input {
    align-items  : center;
    display      : flex;
    margin-bottom: 15px;
    margin-right: 20px;
}

