.wrapper {
    background-color: $v2-primary-color;
    .sidebar {
        background-color: $v2-primary-color;
        border-right: 0px !important;

        .logo {
            background-color: $v2-primary-color;
            height: initial;

            &::after {
                display: none;
            }
        }

        .sidebar-wrapper {
            background-color: $v2-primary-color;

            .nav {
                li {
                    a {
                        color: white;
                        text-transform: inherit;

                        p {
                            color: white !important;
                        }

                        // &:hover {
                        //     background: #435EC9;
                        //     box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.07);
                        //     border-radius: 8px;
                        // }

                        svg {
                            color: white !important;
                            margin-top: 4px;
                        }
                    }

                    &.active {
                        a {
                            background: #435ec9;
                            box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.07);
                            border-radius: 8px;

                            &::before,
                            &::after {
                                display: none;
                            }

                            p {
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }

    .main-panel {
        background-color: $v2-primary-color;
        .content {
            background-color: white;
            margin: 10px;
            border-radius: 20px;
            height: 97vh;
            overflow-y: auto;
            border-top-right-radius: 9px;
            border-bottom-right-radius: 9px;

            @include mobile {
                border-radius: 9px;
            }
            padding: 0px;

            > .row {
                margin-left: initial !important;
                margin-right: initial !important;
            }

            .content {
                height: auto;
            }
        }
    }
}

body {
    ::-webkit-scrollbar {
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        width: 12px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #b6c2d6;
    }
}

.breadcrumbs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 28px;
        margin-right: 10px;
        color: $v2-primary-color;
        cursor: pointer;
    }
}

.root {
    width: 100%;
}
