body {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $v2-default-color;
}

.v2-title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    letter-spacing: -0.02em;
}

h1 {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 28px !important;
}

h2 {
    font-family: Manrope;
    font-style: normal;
    font-weight: 300;
    font-size: 24px !important;
}

h3 {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
}

h4 {
    font-family: Manrope;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
}

h5 {
    font-family: Manrope !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
}

h6 {
    font-family: Manrope !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
}

p {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

a.link {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #E84E1B;
    cursor: pointer;

    &:hover{
        color: #E84E1B;
    }
}

button.v2-button-text {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

.text-center {
    text-align: center;
}

.layout-center {
    text-align: center;
    display: block;
}

label {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

small {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}