.delete-controls {
  display: none;
}

div {
  &:hover {
    >.delete-controls {
      display: flex;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 999;

      button {
        margin: 0px;
        height: 25px;
        width: 25px;
        padding: 0px;
        border-radius: 0px;

        /* &:last-child {
                    border-top-right-radius: 10px !important;
                } */

        svg {
          color: white;
          font-size: 16px;
        }
      }
    }
  }
}