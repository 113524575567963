.quality-stepper {
    border          : 1px solid #B6C2D6;
    border-radius   : 10px;
    margin-top      : 30px;
    margin-inline   : 30px;
    background-color: #fff;
    display         : inline-block;
    // width: 100%;
    flex            : 1;
    display         : flex;
    flex-direction  : column;

    .name-step {
        padding-left : 24px;
        font-size    : 18px;
        margin       : 0 auto;
        // margin-top: 20px;
        display      : block;
        font-family  : "Manrope";

        @include mobile {
            padding-left : 10px;
            margin-bottom: 5px;
            margin-top   : 5px;
        }
    }

    .date-step {
        padding-left: 24px;
        font-size   : 14px;
        color       : #6c757d;
        font-family : "Manrope";

        @include mobile {
            padding-left : 10px;
            margin-bottom: 5px;
        }
    }

    .title-step {
        font-family: "Manrope";
        font-weight: 600;
        font-size  : 14px;
        color      : $v2-default-color;
    }

    .see-around {
        word-break: break-word;
    }

    .subtitle-step {
        // font-size: 80%;
        // font-weight: 400;
        // color: #6c757d !important;
        // font-family: "Manrope";
        font-family     : "Manrope";
        font-weight     : 600;
        font-size       : 14px;
        color           : $v2-default-color;
        // margin-bottom: 5px;

        &.bold {
            font-weight: bold;
        }
    }

    .description-step {
        padding-left  : 24px;
        // font-weight: 400;
        // color: #6c757d !important;
        border-left   : 1px #bdbdbd solid;
        margin-left   : 18px;
        display       : flex;
        flex-direction: column;
        // margin-top: 5px;

        font-family: "Manrope";
        font-weight: 600;
        font-size  : 14px;
        color      : $v2-default-color;

        .v2-primary-btn {
            margin-top: 5px !important;
        }
    }

    .btn-see-step {
        background-color: rgba(0, 0, 0, 0.08);
        outline         : none;
        color           : #6c757d;

        &:hover,
        &:active,
        &:focus {
            background-color: rgba(0, 0, 0, 0.08) !important;
            outline         : none;
            color           : #6c757d !important;
        }

        &:active:hover {
            background-color: rgba(0, 0, 0, 0.08) !important;
            outline         : none;
            color           : #6c757d !important;
        }
    }

    .badge {
        font-size  : 12px !important;
        font-family: "Manrope";
        font-weight: 600 !important;
    }

    .MuiStepConnector {
        margin-left: 18px;
    }

    .quality-header {
        @include mobile {
            min-height: 90px !important;
        }
    }

    .quality-move-badge {
        @include mobile {
            display: block !important;
        }
    }
}

.details-wizard {
    .row {
        .col {
            display       : flex;
            flex-direction: column;
        }
    }
}

.pdf-quality {
    width        : 220px;
    height       : 55px;
    display      : flex;
    margin-right : 20px;
    margin-bottom: 20px;
    color        : black;
}

.btn-save-quality {
    @include mobile {
        display: none;
    }
}

.btn-export-quality {

    @include desktop {
        display: none !important;
    }
}

.btn-quality-settigns {
    @include mobile {
        margin-right: 0px !important;
    }
}

/*
.MuiStepIcon-active {
        color: $primary-color !important;
    }

    .MuiStepIcon-completed {
        color: $primary-color !important;
    }

    .MuiButton-root {
        color:  #6c757d;
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
        margin-top: 15px;
    }
    .MuiButton-text {
        padding: 4px 12px;
    }
*/