.btn,
.navbar .navbar-nav>a.btn {
    border-width : $border-thick;
    font-weight  : $font-weight-semi;
    font-size    : $font-size-small;
    line-height  : $line-height;
    border       : none;
    margin       : 10px 1px;
    border-radius: $border-radius-small;
    padding      : $padding-btn-vertical $padding-btn-horizontal;
    cursor       : pointer;

    @include btn-styles($default-color, $default-states-color);
    @include transition($fast-transition-time, linear);

    // &:hover,
    // &:focus {
    //     @include opacity(1);
    //     outline: 0 !important;
    // }
    // &:active,
    // &.active,
    // .open > &.dropdown-toggle {
    //     @include box-shadow(none);
    //     outline: 0 !important;
    // }

    .badge {
        margin: 0;
    }

    i {
        margin-right: 3px;
    }

    &.btn-icon {
        // see above for color variations
        height     : $btn-icon-size-regular;
        min-width  : $btn-icon-size-regular;
        width      : $btn-icon-size-regular;
        padding    : 0;
        font-size  : $btn-icon-font-size-regular;
        overflow   : hidden;
        position   : relative;
        line-height: normal;

        &.btn-simple {
            padding: 0;
        }

        &.btn-sm {
            height   : $btn-icon-size-small;
            min-width: $btn-icon-size-small;
            width    : $btn-icon-size-small;

            .fa,
            .far,
            .fas,
            .nc-icon {
                font-size: $btn-icon-font-size-small;
            }
        }

        &.btn-lg {
            height   : $btn-icon-size-lg;
            min-width: $btn-icon-size-lg;
            width    : $btn-icon-size-lg;

            .fa,
            .far,
            .fas,
            .nc-icon {
                font-size: $btn-icon-font-size-lg;
            }
        }

        &:not(.btn-footer) .nc-icon,
        &:not(.btn-footer) .fa,
        &:not(.btn-footer) .far,
        &:not(.btn-footer) .fas {
            position   : absolute;
            top        : 50%;
            left       : 50%;
            transform  : translate(-12px, -12px);
            line-height: 1.5626rem;
            width      : 24px;
        }

        &.btn-neutral {
            font-size: 20px;
        }
    }

    &:not(.btn-icon) .nc-icon {
        position: relative;
        top     : 1px;
    }
}

// Apply the mixin to the buttons
// .btn-default { @include btn-styles($default-color, $default-states-color); }
.btn-primary {
    @include btn-styles($primary-color, $primary-states-color);
}

.btn-success {
    @include btn-styles($success-color, $success-states-color);
}

.btn-info {
    @include btn-styles($info-color, $info-states-color);
}

.btn-warning {
    @include btn-styles($warning-color, $warning-states-color);
}

.btn-danger {
    @include btn-styles($danger-color, $danger-states-color);
}

// .btn-neutral { @include btn-styles($white-color, $white-color); }

.btn-outline-default {
    @include btn-outline-styles($default-color, $default-states-color);
}

.btn-outline-primary {
    @include btn-outline-styles($primary-color, $primary-states-color);
}

.btn-outline-success {
    @include btn-outline-styles($success-color, $success-states-color);
}

.btn-outline-info {
    @include btn-outline-styles($info-color, $info-states-color);
}

.btn-outline-warning {
    @include btn-outline-styles($warning-color, $warning-states-color);
}

.btn-outline-danger {
    @include btn-outline-styles($danger-color, $danger-states-color);
}

.btn-outline-neutral {
    @include btn-outline-styles($white-color, $default-states-color);

    &:hover,
    &:focus {
        //  color: $default-states-color;
        //  background-color: $white-color;
    }
}

.btn-neutral {
    @include btn-styles($white-color, $white-color);
    color: $default-color;

    &:hover,
    &:focus {
        //color: $default-states-color;
    }

    &.btn-border {

        &:hover,
        &:focus {
            color: $default-color;
        }

        &:active,
        &.active,
        .open>&.dropdown-toggle {
            background-color: $white-color;
            color           : $default-color;
        }
    }

    &.btn-link:active,
    &.btn-link.active {
        background-color: transparent;
    }
}

.btn {

    &:disabled,
    &[disabled],
    &.disabled {
        @include opacity(0.5);
        pointer-events: none;
    }
}

.btn-simple {
    border          : $border;
    border-color    : $default-color;
    padding         : $padding-btn-vertical - 1 $padding-round-horizontal - 1;
    background-color: $transparent-bg;
}

.btn-simple,
.btn-link {

    &.disabled,
    &:disabled,
    &[disabled],
    fieldset[disabled] & {

        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $transparent-bg;
        }
    }
}

.btn-link {
    border          : $none;
    padding         : $padding-base-vertical $padding-base-horizontal;
    background-color: $transparent-bg;
}

.btn-lg {
    @include btn-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
}

.btn-sm {
    @include btn-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}

.btn-wd {
    min-width: 140px;
}

.btn-group.select {
    width: 100%;
}

.btn-group.select .btn {
    text-align: left;
}

.btn-group.select .caret {
    position  : absolute;
    top       : 50%;
    margin-top: -1px;
    right     : 8px;
}

.btn-group {
    .btn+.btn {
        margin-left: -3px;
    }

    .btn {
        &:focus {
            background-color: $info-color !important;
        }
    }
}

.btn-round {
    border-width : $border-thin;
    border-radius: $btn-round-radius;
    padding-right: $padding-round-horizontal;
    padding-left : $padding-round-horizontal;

    &.btn-simple {
        padding: $padding-btn-vertical - 1 $padding-round-horizontal - 1;
    }
}

.no-caret {
    &.dropdown-toggle::after {
        display: none;
    }
}

.table not(.btn-group) .btn+.btn,
.table .btn-group+.btn-group {
    margin-left: 4px;
}

.btn+.btn,
.btn-group+.btn-group {
    margin-left: 10px;
}

.button-schedule-screen {
    height             : 50px;
    background-color   : $v2-primary-color;
    // border: 2px solid #66615b;
    // color: #66615b;
    // padding-top: 8px;
    // padding-left: 8px;
    // padding-right: 8px;
    // margin-left: 10px;
    border-radius      : 10px;
    font-size          : 14px;
    font-family        : "Manrope";
    margin             : 0px;
    margin-inline-start: 10px;

    &:hover {
        // padding-top: 8px;
        // height: 35px;
        background-color: $v2-primary-color !important;
        // border: 2px solid #66615b !important;
        // color: #66615b !important;
        // padding-left: 8px;
        // padding-right: 8px;
        // margin-left: 10px;
    }

    &:active {
        // padding-top: 8px;
        // height: 35px;
        background-color: $v2-primary-color !important;
        // border: 2px solid #66615b !important;
        // color: #66615b !important;
        // padding-left: 10px;
        // padding-right: 6px;
        // margin-left: 10px;
    }

    &:focus {
        // padding-top: 8px;
        // height: 35px;
        background-color: $v2-primary-color !important;
        // border: 2px solid #66615b !important;
        // color: #66615b !important;
        // padding-left: 10px;
        // padding-right: 6px;
        // margin-left: 10px;
    }
}

.button-quality-export {
    align-self  : center;
    margin-right: 10px;

    &.mobile {
        @media screen and (min-width: 768px){
            display: none;
        }
    }
}

.button-download-schedule {
    height             : 50px;
    width              : 50px;
    min-width          : 50px;
    background-color   : transparent !important;
    border-radius      : 10px;
    font-size          : 14px;
    font-family        : "Manrope";
    border             : $v2-secundary-color 1px solid;
    color              : $v2-secundary-color !important;
    padding            : 5px;
    margin             : 0px;
    margin-inline-start: 10px;

    &:hover,
    &:active,
    &:focus {
        border          : $v2-secundary-color 1px solid;
        background-color: transparent !important;
        color           : $v2-secundary-color !important;
    }
}

.buttons-modal-schedule {
    font-family: "Manrope";
    font-size  : 16px;
}

.btn-refresh-schedule {
    // padding-top: 8px;
    height             : 50px;
    background-color   : $v2-secundary-color !important;
    // border: 2px solid #ef6d2e !important;
    color              : white !important;
    // padding-left: 10px;
    // padding-right: 10px;
    // margin-left: 10px;
    border-radius      : 10px;
    margin             : 0px;
    margin-inline-start: 10px;

    &:hover {
        color           : white !important;
        background-color: $v2-secundary-color !important;
    }

    &:active {
        color           : white !important;
        background-color: $v2-secundary-color !important;
    }

    &:focus {
        color           : white !important;
        background-color: $v2-secundary-color !important;
    }
}

.btn-add-quality {
    margin-top : 0;
    margin-left: 10px;
}

.buttons-schedule-left {
    display      : flex;
    align-items  : center;
    margin-left  : 10px;
    // margin-top: 10px;

    .v2-daterangepicker {
        margin-bottom: 0px;

        input {
            font-size: 14px;
        }
    }

    .v2-input-select {
        margin-top: 10px;
    }

    .clear-selected-button {
        display   : contents;
        margin-top: 0px;
    }

    @include mobile {
        flex-flow      : wrap;
        justify-content: center;
        margin         : 0px;
    }

    button {
        margin-right: 0px;
        margin-left : 10px;
        margin-top  : 10px;
    }
}