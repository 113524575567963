.v2-textinput,
.v2-input-select,
.v2-yearpicker,
.v2-daterangepicker {
    border: 1px solid $v2-grey-color;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    height: 50px;
    position: relative;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    color: #5f5f5f;
    // flex: 1;

    svg {
        // color: $v2-primary-color !important;
    }

    .right-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    > div {
        margin: 0px;
        display: flex;
        align-items: center;

        input {
            font-size: 14px;
        }
    }

    label[class*="styles_float-label"] {
        top: 14px !important;
        left: 10px;
        font-family: Manrope;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
    }

    label[class*="styles_float-active"] {
        top: 0px !important;
        left: 10px;
    }

    .react-select__placeholder {
        font-size: 14px !important;
        line-height: 19px !important;
    }

    input {
        // background-color:  red !important;
        border-radius: 10px;
        height: 50px;
        padding-left: 10px;
        color: $v2-default-color;
        font-family: Manrope;
        font-style: normal;
        font-weight: 600;
        padding-top: 5px;

        &::placeholder {
            font-family: Manrope;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
        }

        &:hover {
            background-color: transparent;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &.active {
        border: 1px solid $v2-info-color;

        label {
            color: $v2-info-color !important;
        }

        .right-icon {
            svg {
                color: $v2-info-color;
            }
        }
    }

    &.error {
        margin-bottom: 0px;
        border: 1px solid $v2-danger-color;

        label {
            color: $v2-danger-color !important;
        }

        .right-icon {
            svg {
                color: $v2-danger-color;
            }
        }
    }

    &.icon {
        align-items: center !important;
        > div {
            // width: 90%;
            flex: 1;
        }
        label[class*="styles_float-label"] {
            font-weight: 600; //400
        }
        svg {
            // color: $v2-primary-color !important;
            font-size: 25px;
            // margin-right: 10px;
        }
        &.input-group {
            color: $v2-primary-color !important;

            label {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 94%;
            }
        }
    }

    &.disabled {
        background-color: #ebeef4;
    }
}

.v2-textinput,
.v2-input-select {
    input:disabled {
        background-color: #ebeef4 !important;
    }
}

.v2-input-select {
    // min-width: 200px;
    position: relative;
    overflow: visible;
    margin-bottom: 10px;

    &.deactivated {
        .label-title {
            display: none;
        }
        .react-select__placeholder {
            padding-top: 0px;
        }
        .dropdown-heading-value > span {
            padding-top: 0px;
            top: 0px;
        }
    }

    .dropdown-content {
        z-index: 99999;
        .item-renderer {
            display: flex;
            align-items: center;
            flex-direction: row;
        }
    }

    .label-title {
        position: absolute;
        top: 0px;
        left: 10px;
    }

    .multi-select,
    .dropdown-container,
    .dropdown-heading,
    .react-select,
    .react-select__control,
    div[class*="value-container"] {
        position: absolute;
        height: 50px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .react-select__control,
    .dropdown-container {
        border: 0px solid transparent !important;
        background-color: transparent !important;
        box-shadow: none !important;

        svg {
            color: $v2-primary-color !important;
        }
    }

    .react-select__single-value,
    .react-select__placeholder,
    .dropdown-heading-value > span {
        padding-top: 8px;
        font-family: Manrope;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $v2-default-color !important;
        position: sticky;
    }

    .dropdown-heading-value > span {
        position: sticky;
        top: 2px;

        //multiplo
    }

    // .react-select__single-value {
    //     padding-top: 20px;
    // }

    .react-select__dropdown-indicator > * {
        display: flex !important;
        font-size: 24px;
    }

    .react-select__dropdown-indicator:after {
        display: none !important;
    }

    .react-select__indicators {
        vertical-align: bottom !important;
    }
}

.v2-yearpicker {
    overflow: visible;
    background-color: transparent;
    margin-bottom: 10px;

    .year-container {
        position: relative;
        display: flex !important;
        align-items: center;
        height: 48px;
        width: 250px;
        border-radius: 10px;
        background-color: transparent;

        .picker-panel {
            z-index: 99999;
        }

        i {
            display: none;
        }

        .arrow-icon-calendar {
            position: absolute;
            right: 150px;
            color: $v2-primary-color;
            font-size: 15px;
        }

        .icon-calendar {
            position: absolute;
            right: 12px;
            color: $v2-primary-color;
            font-size: 20px;
        }

        .year-input {
            border-radius: 10px;
        }

        .year-picker {
            padding: 1px;
            height: 50px;
            margin-left: 10px;
            border-width: 0px;
            background-color: transparent;
            border-radius: 10px;

            &.first {
                border-right-width: 0px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            &.last {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                margin-left: 0px;
                border-left-width: 0px;
                padding-right: 20px;
            }
        }
    }
}

.creatable-input {
    height: 50px;

    &.active {
        height: 60px;

        .react-select__indicators {
            height: 60px;
            vertical-align: middle !important;
        }
    }

    .react-select__value-container--has-value {
        margin-top: 14px;
    }

    .react-select__multi-value {
        border: 1px solid $v2-info-color !important;
    }

    .react-select__multi-value__label {
        color: $v2-info-color !important;
    }

    .react-select__multi-value__remove {
        border-left: 1px solid $v2-info-color !important;
    }
}

.v2-text-error {
    text-align: right;
    color: $v2-danger-color;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}

.v2-daterangepicker {
    margin-bottom: 10px;

    input {
        height: 50px !important;
        color: #5f5f5f !important;
        border: 0px !important;
        text-align: start !important;
        padding-right: 0px;
    }

    input:disabled {
        cursor: pointer;
    }

    svg {
        width: 50px;
        font-size: 20px;
        cursor: pointer;
        color: $v2-primary-color;
    }
}

.modal-datepicker {
    width: 100%;
    padding: 0;

    .rdrCalendarWrapper {
        width: 100%;

        .rdrDateDisplayItemActive {
            border-color: $v2-primary-color !important;
        }

        .rdrDateDisplay {
            color: $v2-primary-color !important;
        }

        .rdrInputRangeInput {
            height: 30px;
        }

        .rdrDayHovered {
            //border: 1px solid #ef6d2e !important;
            //border-radius: 1.333em;
        }

        .rdrDayToday .rdrDayNumber span:after {
            background: $v2-danger-color !important;
        }

        .rdrSelected,
        .rdrInRange,
        .rdrStartEdge,
        .rdrEndEdge {
            background: $v2-primary-color !important;
        }

        .rdrDayStartPreview {
            border-color: $v2-primary-color !important;
            // border-left: 1px solid #ef6d2e !important;
        }

        .rdrDayEndPreview {
            border-color: $v2-primary-color !important;
        }

        .rdrDay {
            border-color: $v2-primary-color !important;
        }

        .rdrDayStartPreview,
        .rdrDayInPreview,
        .rdrDayEndPreview {
            border-color: $v2-secundary-color !important;
        }

        .rdrMonth {
            width: 100%;
        }

        .rdrStartEdge .rdrEndEdge .rdrInRange {
            color: $v2-primary-color !important;
        }
    }

    .button-date-confirm {
        align-self: center;
        margin: 10px auto;
        display: block;
    }
}


.dropdown-heading-value {
    padding-left: 10px;
}
.select-panel{
    padding: 8px;
}

.react-select .react-select__menu {
  border-radius: 0px !important;
  width: 95%;
  max-width: 95%;
}

.react-select .react-select__option {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #5f5f5f;

}