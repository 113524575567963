// @import "../../font-awesome/css/font-awesome.min.css";
.year-container {

  .picker-panel {
    position: absolute;
    top: 32px;
    width: 240px;
    height: 220px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: white;
    transition: all 0.3s;
  }

  @include mobile {
    .picker-panel {
      top: 50px !important;
      left: -22px !important;

      .header {
        justify-content: center;
      }
      .footer {
        justify-content: center;
      }
    }
  }

  /* INPUT */

  .input-wrapper {
    position: relative;
    height: 100%;
    min-width: 85px;
  }

  .imput-wrapper-selected {
  }

  .year-input {
    border: 0;
    width: 100%;
    cursor: auto;
    outline: 0;
    height: 100%;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    padding: 0 10px;
    border-radius: 4px;
    box-sizing: border-box;

    &:hover  {
      background-color: transparent !important;
    }
  }

  .input-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    right: 14px;
    transform: translate(0, -50%);
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 2s;
  }

  .input-icon:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .input-icon-close {
    display: none;
  }

  .imput-wrapper-selected:hover > .input-icon-close {
    display: block;
    color: #1890ff;
  }

  .imput-wrapper-selected:hover > .input-icon-calendar {
    display: none;
  }

  /* YEAR PICKER */

  .year-picker {
    position: relative;
    outline: none;
    width: 100px;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    background-clip: padding-box;
    line-height: 1.5;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border: 1px solid transparent;
    border-color: #d9d9d9;
  }

  .year-picker:hover {
    color: #40a9ff;
    background-color: transparent;
    border-color: #40a9ff;
  }

  /* HEADER */

  .header {
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0 !important;
  }

  .current-year {
    display: inline-block;
    color: rgba(0, 0, 0, 0.85);
  }

  .nav-button {
    position: absolute;
    background-color: transparent;
    border: none;
    transition: color 0.3s;
    color: rgba(0, 0, 0, 0.45);
    padding-bottom: 0;
    padding-top: 0;
  }

  .nav-button * {
    line-height: 40px;
  }

  .nav-button-next {
    right: 35px;
  }

  .nav-button-back {
    left: 35px;
  }

  .jump-button-next {
    right: 7px;
  }

  .jump-button-back {
    left: 7px;
  }

  .nav-button:hover {
    color: #1890ff;
  }

  /* BODY */
  .body {
    border-top: 1px solid #e8e8e8;
  }

  .year-wrapper {
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-weight: normal;
    width: 33.3%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .year {
    display: inline-block;
    padding: 2px;
    border-radius: 4px;
  }

  .selected-year {
    background: #1890ff;
    color: #fff !important;
    border: 1px solid transparent;
  }

  /* FOOTER */
  a {
    cursor: pointer;
  }

  .footer {
    border-top: 1px solid #e8e8e8;
    line-height: 38px;
    padding: 0 12px;
  }

  .footer-btn {
    text-align: center;
    display: block;
  }

  .footer-today {
    display: inline-block;
    text-align: center;
    margin: 0;
    color: #1890ff;
    transition: color 0.3s ease;
}
}