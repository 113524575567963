.comment-details-container {
  .fr-box {
    height: 100% !important;
  }

  .fr-wrapper {
    position: relative;
    height: 100% !important;
    overflow: hidden !important;
    // height: 430px !important;
    // &:after {
    //     text-align: center;
    //     content: "Área de corte do relatório";
    //     top: 430px;
    //     position: absolute;
    //     height: 20px;
    //     width: 100%;
    //     background-color: red;
    //     color: white;
    //     opacity: 0.7;
    // }
  }

  .fr-fullscreen-wrapper {
    .fr-wrapper {
      height: initial !important;
    }
  }

  .section-buttons {
    display: flex;
  }

  .section-button {
    padding: 0.2rem;
    float: right;
    margin: 2px;
    display: flex;

    i {
      margin: 0px;
    }

    button {
      margin-left: 10px;
    }
  }

  .section-span {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    margin-right: 10px;
    // margin-right: 74px;
    // margin-left: auto;
  }

  .dropdown-content {
    padding-bottom: 30px;
  }
}
