$pageSectionHeight: 480;

.list-group-item {
  padding: 0px; // 40px;
}

.alert-list {
  overflow: auto;
  height: 60vh;
  padding: 18px;
  margin-inline: -30px;

  .items-without {
    font-family: "Manrope";
    font-size: 16px;
    text-align: center;
    font-weight: 600;
  }

  .alert-item {
    padding: 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 12px 20px rgba(31, 77, 155, 0.07);
    border-radius: 8px;
    background-color: white;
    margin-bottom: 15px;
    overflow-wrap: anywhere;
    font-family: "Manrope";
    font-size: 14px;

    .alert-header {
      display: flex;

      .alert-header-details {
        flex: 1;
      }
    }

    .alert-title {
      font-weight: 600;
      color: $v2-default-color;
    }

    .alert-text {
      font-weight: 400;
      color: rgba(95, 95, 95, 0.6);
    }
    .alert-text-confirmed {
      font-weight: 400;
      color: #2A3F92;
    }

    // .alert-description {
    //   overflow-wrap: anywhere;
    // }
  }
}

.report-header {
  padding: 0.75rem 1.25rem;
  font-family: "Manrope";
}

.content-report {
  padding: 0px 40px !important;

  @include mobile {
    padding: 0px 5px !important;
  }
}

.content-section {
  display: flex;
  padding: 0px 40px;

  @include mobile {
    padding: 0px 5px !important;
  }

  .section-button {
    padding: 0.2rem;
    float: right;
    margin: 2px;
    display: flex;

    i {
      margin: 0px;
    }
  }

  .section-span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 32px;
    margin-right: 5px;
    margin-left: auto;
    font-family: "Manrope";
  }

  button {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  button:last-child {
    margin-left: 10px;
  }
}

.content-report {
  padding: 0px 15px;
  position: relative;
  margin: 0 auto;
  max-width: 1020px;

  .float-button,
  .div-border {
    display: none !important;
  }

  .list-margin {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px !important;
    align-items: center;
    .hide-mobile {
      display: flex;
    }

    &.hidden {
      overflow: hidden;

      div {
        p {
          font-size: 12px;
          font-family: $sans-serif-family;
        }
      }
    }
  }

  .page-break {
    position: absolute;
    top: #{$pageSectionHeight}px;
    left: 0;
    right: 0;
    height: 1px;
    border-bottom: 1px dashed darkgray;

    &.comment {
      top: #{$pageSectionHeight * 2}px;
    }
  }

  .report-middle-section {
    position: relative;

    &:not(.comment) {
      min-height: #{$pageSectionHeight}px;
    }
  }

  .not-in-page {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: #{$pageSectionHeight}px;
    background-color: red;
    opacity: 0.2;
  }

  .edit {
    .report-middle-section {
      &:not(.comment) {
        min-height: #{$pageSectionHeight}px;
      }
    }

    .page-break {
      top: #{$pageSectionHeight}px;

      &.comment {
        top: #{$pageSectionHeight * 2}px;
      }
    }

    .not-in-page {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: #{$pageSectionHeight * 2}px;
      background-color: red;
      opacity: 0.4;
    }
  }

  &:hover {
    .float-button {
      display: block !important;
    }

    .div-border {
      display: flex !important;
    }
  }
}

a.has-filter {
  cursor: pointer;
}

.button-filter {
  margin: 0 auto;
  margin-bottom: 20px;
}

.alert-task {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 10px;
  font-family: "Manrope";

  &.alert-secondary {
    color: $v2-default-color;
    background-color: #e5e9f0;
    border-radius: 10px;

    // @include mobile {
    //   margin-left: 15px;
    //   margin-right: 0px;
    //   width: 95%;
    // }
  }
}

.alert-task-title {
  font-size: 18px;

  @include mobile {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row-alert-task {
  @include mobile {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.simple-remove {
  border-radius: 100% !important;
  width: 30px;
  height: 30px;
  margin-left: 20px;
  margin-bottom: 0px;
  margin-top: 0px;

  i {
    position: relative;
    right: 12px;
    top: -2px;
  }
}

.box-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  border: 1px solid #b6c2d6;
  border-radius: 10px;
  // padding: 10px;
  z-index: 3;
  background-color: white;

  > div {
    display: flex;
    flex-flow: row wrap;

    .react-draggable {
      display: inline-table;
      height: 0px;
    }
  }

  .paste-button {
    float: right;
  }

  .box {
    position: relative;
    display: inline-block;
    border: 4px solid grey !important;
    border-radius: 7px;

    .button-rotate-image {
      display: none;
      position: absolute;
      top: -10px;
      right: 25px;
      z-index: 4;
      min-width: 25px !important;
      width: 25px !important;
      height: 25px !important;
      border-radius: 2px !important;
      padding: 2px;

      i {
        font-size: 20px;
      }
    }

    .button-up-image {
      display: none;
      position: absolute;
      top: -10px;
      right: 51px;
      z-index: 4;
      min-width: 25px !important;
      width: 25px !important;
      height: 25px !important;
      border-radius: 2px !important;
      padding: 2px;

      i {
        font-size: 20px;
      }
    }

    .delete-controls {
      .btn-danger {
        width: 25px !important;
        height: 25px !important;
        border-radius: 2px !important;
      }
    }

    img {
      height: 100%;
    }

    .drag-icon {
      display: none;
    }

    &:hover {
      .button-rotate-image,
      .button-up-image {
        display: block;
      }

      .drag-icon {
        display: block;
        position: absolute;
        bottom: 10px;
        left: 10px;
        cursor: move;

        &.top {
          top: 10px;
        }

        i {
          color: white;
        }
      }
    }

    &.black {
      border-color: black !important;
    }

    &.white {
      border-color: white !important;
    }
  }
}

.resizable-box {
  height: auto !important;
  position: relative !important;
}

.react-resizable {
  min-height: 100px;
}

.image-resizable {
  .react-resizable-handle {
    display: none;
  }
}

.ptro-holder-wrapper {
  z-index: 9999999999;
}

.report-data {
  .fr-box {
    z-index: 3 !important;
  }

  .fr-wrapper {
    overflow: visible;
    height: auto !important;

    &:before {
      content: "title";
      // background-color: white;
      height: 20px;
      position: absolute;
      z-index: 4;
      left: 6px;
      // font-size: 16px;
      // top: 3px;
      line-height: 10px;
      padding: 5px;
      // font-weight: bold;
      color: $v2-info-color;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }
  }

  &.commentItems {
    flex-direction: column;

    .fr-wrapper {
      overflow: visible !important;
    }
  }

  .localization {
    .fr-wrapper {
      &:before {
        content: "Localização";
      }
      .fr-view {
        border-radius: 10px !important;
      }
    }
  }

  .apoint {
    .fr-wrapper {
      &:before {
        content: "Apontamento";
      }
      .fr-view {
        border-radius: 10px !important;
      }
    }
  }

  .apoint-dynamic {
    .fr-wrapper {
      &:before {
        content: attr(data-header-content);
      }
    }
  }

  .resolution {
    .fr-wrapper {
      &:before {
        content: "Resolução";
      }
      .fr-view {
        border-radius: 10px !important;
      }
    }
  }

  .resolution-dynamic {
    .fr-wrapper {
      &:before {
        content: attr(data-header-content);
      }
    }
  }

  .fr-iframe {
    min-height: 50px;
  }

  .editor-container {
    position: relative;
  }

  .float-button-container {
    position: relative;
  }

  .float-button {
    position: absolute;
    right: -40px;
    top: 0px;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    padding: 5px !important;
    margin-right: 2px;

    i {
      right: 9px;
      position: relative;
    }

    &.top2 {
      top: 40px;
    }

    &.top3 {
      top: 80px;
    }

    svg {
      height: 100%;
    }
  }

  .div-border {
    position: absolute;
    right: -34px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.top4 {
      top: 135px;

      a {
        margin-bottom: 5px;
        border-radius: 8px;
        width: 25px;
        height: 25px;
      }
    }
  }
}

.report-data-comment {
  .fr-box {
    z-index: 3 !important;
  }

  .fr-wrapper {
    // &:before {
    //   content: "title";
    //   background-color: white;
    //   height: 20px;
    //   position: absolute;
    //   z-index: 4;
    //   left: 20px;
    //   font-size: 16px;
    //   top: -10px;
    //   line-height: 10px;
    //   padding: 5px;
    //   // font-weight: bold;
    // }
    &:before {
      content: "title";
      // background-color: white;
      height: 20px;
      position: absolute;
      z-index: 4;
      left: 6px;
      // font-size: 16px;
      // top: 3px;
      line-height: 10px;
      padding: 5px;
      // font-weight: bold;
      color: $v2-info-color;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }
  }

  &.commentItems {
    flex-direction: column;

    .fr-box {
      height: inherit !important;
      padding-bottom: 5px;
    }

    .fr-wrapper {
      overflow: visible !important;
      height: inherit !important;
      padding-bottom: 5px;
    }
  }

  .localization {
    .fr-wrapper {
      &:before {
        content: "Localização";
      }
    }
  }

  .apoint {
    .fr-wrapper {
      &:before {
        content: "Apontamento";
      }
    }
  }

  .apoint-dynamic {
    .fr-wrapper {
      &:before {
        content: attr(data-header-content);
      }
    }
  }

  .fr-iframe {
    min-height: 50px;
    height: inherit !important;
    padding-bottom: 5px;
  }

  .editor-container {
    position: relative;
  }

  .float-button-container {
    position: relative;
  }

  .float-button {
    position: absolute;
    right: -40px;
    top: 0px;
    border-radius: 100%;
    width: 35px;
    height: 35px;

    i {
      right: 9px;
      position: relative;
    }

    &.top2 {
      top: 40px;
    }

    &.top3 {
      top: 80px;
    }
  }

  .div-border {
    position: absolute;
    right: -30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.top4 {
      top: 135px;

      a {
        margin-bottom: 5px;
      }
    }
  }
}

.fr-fullscreen-wrapper {
  z-index: 99999999;

  .fr-wrapper {
    background-color: white;

    &:before {
      display: none;
    }
  }
}

.fr-wrapper {
  overflow: auto;
}

.comment-save {
  margin-left: auto;
  margin-right: 15px;
}

.react-confirm-alert-body {
  h1 {
    font-size: 20px;
  }
}

.react-confirm-alert-button-group {
  justify-content: flex-end;
}

.pdf-modal {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
  width: 80%;
  height: 100vh;

  .modal-content {
    height: 100vh;

    iframe {
      height: 100%;
    }
  }
}

.button-pdf {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  span {
    margin-top: 20px;
  }
}

/* .tui-image-editor-container {
  position: absolute;
  top: 0;
  bottom: 0;
} */

.modal-edit-image {
  .modal-body {
    position: relative;
  }
}

.download-pdf {
  width: 80px;
}

.badge-pdf {
  margin: 0 auto;
  display: block;
  text-align: center;
}

@include mobile {
  .hide-mobile {
    display: none !important;
  }
}
