.modal-header {
    border-bottom: 1px solid $medium-gray;
    padding: 7px 20px;
    text-align: center;
    // display: block !important;

    &.no-border-header{
        border-bottom: 0 none !important;
        & .modal-title{
            margin-top: 20px;

        }
    }
    button.close{
      &:focus {
        outline: none;
      }
    }

    .modal-profile {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      text-align: center;
      line-height: 6.4;
      border: 1px solid rgba(0,0,0,.3);

      i {
        font-size: 30px;
      }
    }
}
.modal-dialog{
    &.modal-sm,
    &.modal-register{
        .modal-header{
            button.close{
                margin-top: 0;
            }
        }
    }
}

.modal-content {
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
    .modal-header{
        h6{
            margin-top: 10px;
        }
    }

    .card-title {
        margin: 0px;
    }

    .card-header, .card-body {
        padding: 0px !important;
    }

    .description {
        margin: 15px;
        margin-bottom: 15px !important;
    }

    .tab-content {
        padding: 0px !important;
    }
    
    .details-title-modal {
        font-size: 1.57em;
        margin: 0 auto;
        margin-top: 20px;
    }

    .details-subtitle-modal {
        font-size: 1.35em;
        margin: 0 auto;
        margin-top: 20px;

        &.two {
            font-size: 1.2em;
        }
    }

    .details-title {
        margin-top: 10px;
    }

    .details-description {
        font-weight: 400;
        color: #6c757d !important;

        svg {
            margin-right: 10px;
        }
    }

    .details-history {
        font-weight: 400;
        color: #6c757d !important;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .details-input {
        padding: 0.375rem 0.75rem;
        min-height: 150px;
    }
    .details-input-quality {
        padding: 0.375rem 0.75rem;
        max-height: 40px !important;
    }
}

.modal-dialog {
    padding-top: 0px;
}
.modal-body{
    padding: 10px 30px;
    color: #000;

    .overflow-body {
        min-height: 50vh;
        overflow-y: auto;
        padding-left: 15px;
        padding-right: 15px;
        max-height: 50vh;
        margin-top: 15px;
    }

    iframe {
        width: 100%;
    }
}
.modal-footer {
    border-top: 1px solid $medium-gray;
    padding: 5px;

    &.no-border-footer{
        border-top: 0 none;
    }
}
.modal-footer .left-side,
.modal-footer .right-side{
    display: inline-block;
    text-align: center;
    width: 50%;
    padding: 5px;
}
.modal-footer .btn-link{
    padding: 20px;
    width: 100%;
    margin: 0;
}
.modal-footer .divider{
    background-color: $medium-gray;
    display: inline-block;
    float: inherit;
    height: 63px;
    margin: 0px -3px;
    // position: absolute;
    width: 1px;
}
.modal-register .modal-footer{
    text-align: center;
    margin-bottom: 25px;
    padding: 20px 0 15px;
    span{
        width: 100%;
    }
}

.modal-edit-image {
    padding-top: 0px !important;

    .modal-header {
        padding: 5px !important;

        .close {
            padding: 20px !important;
        }
    }

    .modal-content {
        height: calc(100vh - 58px) !important;

        .tui-image-editor-container {
            height: 100% !important;
        }

        .tui-image-editor-main {
            top: 0 !important;
        }
    }

    .modal-footer {
        padding: 0px;
        padding-right: 10px;
    }    

    .tui-image-editor {
        // position: absolute !important;
        // top: 0 !important;
        // left: 0 !important;
        // right: 0 !important;
        // bottom: 0 !important;
        // height: initial !important;
        // width: initial !important;

        .tui-image-editor-canvas-container {
            margin: 0 auto;
        }
    }
}

.modal-content {
    .fr-inline {
        z-index: initial !important;
    }
}

.cancel-button-modal {
    width: 100px !important;
    background-color: white !important;
    color: #66615B !important;
}