.card-tasks {
  text-align: left;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;

  // .row {
  //   padding-left: 15px;
  //   padding-right: 15px;
  // }

  .row-editor {
    // margin-top: 15px;

    .fr-toolbar {
      border: 1px solid $v2-grey-color !important;
    }
    .fr-box.fr-basic .fr-wrapper {
      border: 1px solid $v2-grey-color !important;
      border-bottom-color: $v2-grey-color;
      border-top: transparent !important;
    }

    .second-toolbar {
      border: 1px solid $v2-grey-color;
      border-top: transparent;
    }
  }

  .table tbody {
    td:last-child {
      padding-right: 0;
      display: inline-flex;

      .btn {
        padding: 3px;
      }
    }

    td:first-child {
      padding-left: 0;
    }

    td {
      padding: 12px 8px !important;
    }
  }

  .table-full-width {
    padding-bottom: 0 !important;
  }

  .card-footer {
    padding-top: 0;
  }

  .table {
    margin-bottom: 0 !important;
  }

  .row-button {
    padding-right: 15px;
    padding-left: 15px;
    justify-content: flex-end;

    .button-alert {
      background-color: $white-color;
      color: $black-color;
      border: 1px solid $medium-gray !important;
      height: 35px;

      i {
        margin: 0px;
      }

      &.active,
      &:hover,
      &:focus,
      &:active:hover,
      &:active:focus {
        background-color: $primary-color !important;
        color: $white-color !important;
      }
    }
  }
}
