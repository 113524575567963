.modal-open .modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.all-modal {
    width: 424px;

    @include mobile {
        min-width: 30%;
    }

    .modal-content {
        border-radius: 20px;
    }
}

.new-modal {
    padding: 30px 40px;
    max-height: 100%;
    // overflow: auto;
    // min-height: 40vh;
    display: flex;
    flex-direction: column;

    @include mobile {
        max-height: initial;
        padding: 30px 30px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .title {
            font-size: 24px;
            font-weight: 300;
        }

        .icon {
            color: $v2-primary-color;
            font-size: 22px;
            cursor: pointer;
        }
    }

    .body {
        flex: 1;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        padding: 0px;
        margin: 0px;
    }

    .label-error {
        display: flex;
        justify-content: center;
        color: red;
    }
}

.swal-button {
    height: 41px;
    border-radius: 10px;
    font-family: "Manrope";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    &.confirm {
        background-color: $v2-primary-color !important;
    }
    &.warning {
        background-color: $v2-secundary-color !important;
    }
    &.danger {
        background-color: $v2-danger-color !important;
    }
}

.swal-overlay {
    z-index: 999999999;
}

.swal-button--cancel {
    background-color: transparent !important;
    color: #e84e1b !important;
    border: 1px solid #e84e1b;
}

.swal-icon {
    margin: 16px auto;
}

.swal-icon--warning {
    // color: $v2-secundary-color !important;
    @keyframes pulseWarning {
        0% {
            border-color: #f8d486;
        }
        100% {
            border-color: $v2-secundary-color;
        }
    }
    span {
        background-color: $v2-secundary-color;
    }
}

.swal-icon--error {
    border-color: $v2-danger-color;
    span {
        background-color: $v2-danger-color;
    }
}

.swal-icon--success {
    border-color: $v2-primary-color;
    span {
        background-color: $v2-primary-color;
    }
}

.swal-icon--success__ring {
    border: 4px solid rgba(42, 63, 146, 0.2);
}

.swal-modal {
    // height: 506px;
    width: 424px;
    border-radius: 20px;

    @include mobile {
        width: auto;
    }
}

.swal-title {
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    color: #5f5f5f;
}

.swal-text {
    font-family: "Manrope";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #5f5f5f;
    // height: 40%;
}

.quality-modal {
    width: 989px;
    max-width: initial;

    .new-modal {
        height: 640px;

        @include mobile {
            height: auto;
        }
    }
}

.filter-modal {
    padding-right: 15px;
}

.alert-modal {
    width: 989px;
    max-width: initial;

    .new-modal {
        // height: 640px;

        @include mobile {
            height: auto;
        }
    }
}

.report-modal {
    width: 989px;
    max-width: initial;

    .modal-content {
        max-height: 93vh;
    }

    .new-modal {
        overflow-y: auto;
        // height: 640px;

        @include mobile {
            height: auto;
        }

        .body {
            overflow-y: auto;
            overflow-x: hidden;
            padding-inline: 30px;
            margin-inline: -30px;

            @include mobile {
                overflow: initial;
            }

            .custom-table {
                > div > div > div > div {
                    max-height: 100vh !important;
                }
            }
        }
    }

    &.schedule .new-modal .body {
        overflow-y: initial;
        overflow-x: initial;
    }

    &.modal-edit-image .new-modal {
        height: 100%;
        max-height: none;
    }

    &.pdf-modal {
        height: auto;

        @include mobile {
            margin-top: 0.7rem !important;
            margin-bottom: 0.7rem !important;
        }

        .modal-content {
            .new-modal {
                overflow: overlay;
            }
        }
    }

    &.comment-report .new-modal .body {
        padding-inline: 0px;
        .list-comment {
            margin: 0px;
        }
        .custom-table {
            > div > div > div > div {
                max-height: none !important;
                tr {
                    height: auto !important;
                }
            }
        }
    }

    &.modal-gallery-images {
        .ReactGridGallery {
            max-height: initial !important;
        }
    }

    &.modal-quality-details .new-modal .body {
        .content-report {
            padding: 0px !important;
        }
    }
}

.link-image-modal {
    .subtitle-center {
        font-family: Manrope;
        font-weight: 600;
        color: #5f5f5f;
        margin-bottom: 20px;
    }

    .fr-quick-insert {
        display: none;
    }

    // .row {
    //     margin-top: 0px !important;
    //     margin-bottom: 0px !important;
    // }
}
