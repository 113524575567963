.card-full {
    // height        : 100vh;
    flex-direction: row;
    display       : flex;
    flex          : 1;
    @include mobile {
        flex-direction: column;
        display       : flex;
    flex          : 1;
      }
}

.card-dashboard {
    // width       : 50%;
    // height      : 50%;
    flex        : 1;
    margin-right: 5px;
    padding     : 20px;
    display     : flex;

    .card-title {
        font-size    : 1.3em;
        margin       : 0px;
        margin-bottom: 30px;
    }
    @include mobile {
        margin-bottom:20px
      }
    .line-chart {
        height    : 15rem;
        min-height: 300px;
        width     : 70%;
        align-self: center;
    }
}

.btn-dashboard {
    // height         : 100%;
    display           : flex;
    justify-content   : flex-end;
    // align-items    : end;
}

.card-legend {
    display        : flex;
    justify-content: flex-start;

}

.card-columm {
    display       : flex;
    flex-direction: column;
}

.dashboard-menu {
    @include desktop {
        height: calc(100vh - 140px);
        display: flex;
        justify-content: center;
        align-items: center;
    
        .card-dashboard {
            margin-left: 30px;
            margin-right: 30px;
        }
    }
    
}