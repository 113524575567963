.div-input-send{
    background-color: #f5f5f5;
    border-top: 1px solid #d0dae5;
    display: flex;

}
.div-input-margin{
    margin:15px;
    display: flex;
    width: 100%;
    align-items: center;
}
.send-input{
    width: 90%;
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 15px;
    background: white;
    border: 0px;
    outline:none;
}
.send-message{
    font-size: 20px;
    margin-left: 15px;
    cursor:pointer;
    color: $v2-primary-color !important;
}
.send-file{
    font-size: 20px;
    margin-right: 15px;
    cursor:pointer;
    color: $v2-primary-color !important;
}

.spinner-send{
    margin-left: 15px;
    height: 25px;
    width: 25px;
}