.edit{
    position: absolute;
    background: white;
    border-radius: 5px;
      margin: 5px;
      box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.09);
    .li{
    list-style: none;
    }
}
.div-Btn{
    // margin: 5px 20px;
    cursor: pointer;
    padding: 5px 15px;
    font-size: 13px;
    font-weight: 400;
    &:hover{
        background:#e9eef4;
        border-radius: 5px;
    }
}
.dropdown-content {
    background-color: white;

    .select-item {
        padding-left: 10px;
    }
}