.avatar-user-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $v2-default-color;

    .avatar-info {
        display: flex;
        flex-direction: column;
        min-width: 100px;

        label {
            margin-bottom: 4px;
        }
    }

    .avatar-img {
        height: 42px;
        width: 42px;
        border-radius: 100%;
        margin-right: 18px;
    }
    
}