.company{
    .button-save-company {
        align-self: flex-end;
    }    
}

.card-company-details {
    //margin: 0 auto;

    /*
    .react-dropzone {
        margin: 0 auto;
        width: 250px;
        height: 250px;
        margin-bottom: 20px;
    }
    */
}

.card-company-details {
    .react-select__placeholder {
        color: #66615b !important;
        font-size: 14px !important;
    }
    
    .btn-delete-company {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $danger-color !important;
        width: 140px;
    
        i {
            font-size: 20px;            
        }
       
    }
    .title-company {
        width: 100%
    }

    .header-card-company {
        display: flex;
    }
}