.v2-company-details {
    display: flex;
    flex-direction: column;

    .tile,
    .file-container,
    .react-dropzone {
        height: 135px;
        width: 135px;
    }

    .header-company {
        display: flex;
        flex-direction: row;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        padding-bottom: 30px;

        &.work {
            padding: 0px;

            // .work-name {
            //     margin-inline: 5px;
            //     flex: 3;
            // }

            // .work-status {
            //     margin-left: 5px;
            //     flex: 1;
            // }

            // .work-address {
            //     margin-left: 5px;
            // }

            @include mobile {
                flex-direction: column;

                .col-info {
                    padding-left: 0px;
                }

                // .work-name {
                    // margin-inline: 0px;
                    // flex: 3;
                // }

                // .work-status {
                //     flex: 1;
                // }

                // .work-address {
                //     margin-left: 0px;
                // }
            }
        }
    }

    .material-filter {
        margin-top: 0px;
    }

    .col-img {
        padding-top: 15px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .col-info {
        padding-left: 30px;
        padding-right: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .v2-add-icon {
        display: contents;
    }
}
.file-container {
    .tile {
        //     width: 135px;
        //     height: 135px;
        //     // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 55.56%, #000000 100%);
        border: 0 solid transparent;
        height: 135px;
        width: 135px;
        //     margin-left: 30px;

        div.file-item-div {
            border: 2.72727px dashed #677da3;
            width: 136px;
            height: 136px;
            border-radius: 100%;
            // position: relative;
            margin: 0 auto;
        }

        div.img {
            padding: 2px;
            width: 130px;
            height: 130px;
            border-radius: 100%;
            background-size: 135px 135px !important;
            margin: 0 auto;

            box-sizing: border-box;
        }

        .check-select {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99;

            .custom-control-label {
                display: none;
            }
        }
    }
}
.col-img {
    flex: 0;
}

.react-dropzone {
    &.circle {
        border-radius: 100%;
        border: 2.72727px dashed #677da3;
    }
    &.mini {
        width: 135px !important;
        height: 135px !important;
    }
}
