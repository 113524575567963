.work-card {
    display: flex;
    width: 356px;
    height: 125px;
    background: #ffffff;
    border: 1px solid #b6c2d6;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    text-decoration: none !important;

    @include mobile {
        margin-left: 0px;
        margin-right: 0px;
    }

    .img {
        border: 1px solid #b6c2d6;
        box-sizing: border-box;
        border-radius: 8px;
        width: 166px;
        height: 101px;
        display: inline-table;
        background-size: 166px 101px !important;
    }

    .work-card-info {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        padding-left: 12px;

        h5 {
            color: $v2-primary-color;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .work-card-footer {
            display: flex;
            justify-content: center;
            align-items: center;

            .work-badge {
                background: $v2-secundary-color;
                border-radius: 90px;
                padding: 5px 12px 8px;
                color: white;
                margin-right: 12px;

                &.danger {
                    background-color: $v2-danger-color;
                }
            }

            .status {
                color: $v2-primary-color;

                &.finish {
                    color: $v2-success-color;
                }
            }
        }
    }
}

.details-works {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 20px;
}

.flex1 {
    flex: 1;
}

.work-row {
    display: flex;

    .img {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        display: inline-table;
        background-size: 44px auto !important;
        margin-right: 30px;
        border: 2px solid $v2-grey-color;
    }

    .work-col {
        h5 {
            color: $v2-primary-color;
            margin-bottom: 0px;
        }

        p {
            margin-bottom: 0px;
        }
    }
}

.company-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    padding-left: 0px;
    padding-right: 0px;

    &.padding {
        padding-inline: 30px;
    }
}

.row-quality {
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    .rc-checkbox:hover .rc-checkbox-inner,
    .rc-checkbox-input:focus + .rc-checkbox-inner {
        border-color: $v2-primary-color;
    }

    .rc-checkbox-checked .rc-checkbox-inner:after {
        left: 6px;
        width: 6px;
        height: 12px;
    }

    span {
        width: 20px;
        height: 20px;
        margin-right: 16px;
    }

    .rc-checkbox-checked .rc-checkbox-inner {
        background-color: $v2-primary-color;
        border-color: $v2-primary-color;
        font-size: 20px;
    }
}
