@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

$v2-primary-color:#2A3F92;
$v2-secundary-color:#E84E1B;
$v2-info-color:#35A8E0;
$v2-warning-color:#F8B133;
$v2-default-color:#5F5F5F;
$v2-danger-color: #E94D4D;
$v2-light-color:  #F8F8FA;
$v2-success-color: green;
$v2-dark-color: #000000;
$v2-grey-color: #B6C2D6;
$v2-icon-blue-color:#677DA3;
$v2-mini-btn-bg-color: #F8F8FA;

.v2-primary-color {
    color: $v2-primary-color;

    &:hover, &:focus {
        color: $v2-primary-color;
    }
}