.div-full {
    z-index         : 99999;
    background-color: #fff;
    position        : relative;
}

.div-collumn {
    flex-direction: column;
    display       : flex;
    align-items   : center;

    >span {
        margin-bottom: 15px;
    }
}

.img {
    width: 400px;
}

.text-login {
    cursor     : pointer;
    text-decoration:dashed;
    
}