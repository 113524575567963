.blank-dashboard {
  flex: 1;

  .blank-logo {
    display: flex;
    margin: auto;
  }

  .blank-row {
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .blank-store {
      margin: 5px;
      // width: 120px;
    }

    .blank-label {
      font-size: 16px;
    }
  }

  .blank-row-logo {
    min-height: 300px;
  }
}

@media print {

  .sidebar,
  .main-panel nav,
  #filter-dashboard {
    display: none;
  }

  .main-panel {
    width: 100% !important
  }

  #printarea {
    display: block;
  }

  #printarea .col {
    page-break-inside: avoid;
  }
}

.dashboard-animation {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  font-family: 'Manrope';

  .title {}

  .subtitle {}
}


@media only screen and (max-device-width: 480px) {
  .dashboard-step-tutorial {
    display: none;
  }
}

.modal-comment {
  .modal-content {
    height: 100%;
  }
}

.gallery-focus {
  border: 3px solid #ee6f37;
}

.row-cards {
  display: flex;
  flex: 1;
}

@media print {
  .print-dashboard {
    .col-lg-6 {
      .card {
        >div {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }

    .recurrency-card {
      >div {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}