.filter {
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 12px;

  .show {
    // color: #252422 !important;
    // background-color: white !important;
    font-size: 15px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 5px 15px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 576px) {
    .clearfix {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
    }
  }

  .react-select {
    .react-select__control {
      background-color: white;
      border: 1px solid #DDDDDD;
      border-radius: 4px;

      .react-select__placeholder {
        color: #66615b;
      }
    }
  }

  .dates {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #DDDDDD;
    border-radius: 4px;

    .form-control {
      background-color: transparent;
      border: 0px solid transparent;
      font-size: 0.8571em;
      height: 38px;
    }

    .rdt:last-child {
      border-left: 1px solid #DDDDDD;
    }
  }

  .filter-title {
    margin-bottom: 7px;
    display: block;
  }

  .collapse {
    background-color: transparent !important;
    padding: 0;

    .row {
      width: 100%;

      >div:last-child {
        padding-right: 0px;
      }

      >div:first-child {
        padding-left: 0px;
      }
    }
  }

  .btn-group {
    float: right;

    button {
      border-radius: 4px;
      height: 33px;
      padding: 0 15px;
    }

    .btn:focus {
      background-color: $primary-color !important;
    }
  }
}

.filter-container {
  display: flex;
  justify-content: flex-end;

  .actions {
    display: flex;    
    
    &.wrap {
      flex-wrap: wrap;
    }

    .picky {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-left: 10px;

      >button {
        background-color: white;
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        height: 35px;
        width: 180px;
        padding-left: 10px;
        font-size: 14px;
        font-family: Montserrat, "Helvetica Neue", Arial, sans-serif;
        font-stretch: 100%;
        font-style: normal;
        font-variant-caps: normal;
        font-variant-east-asian: normal;
        font-variant-ligatures: normal;
        font-variant-numeric: normal;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 16.625px;

        span {
          color: #9A9A9A !important;
          font-size: 0.8571em;
        }

        &::after {
          color: #4d4d4d;
        }

        .picky__placeholder {
          padding-top: 3px;
          max-width: 85%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block !important;
        }
      }

      .option {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block !important;
      }

    }

    :focus {
      outline: none !important;
    }


    .react-select,
    .react-bootstrap-daterangepicker-container {
      // margin-left: 10px;
      // width: 210px;
      display: flex !important;
      align-items: center;

      .react-select__placeholder {
        font-size: 14px !important;
        font: inherit;
      }

      .react-select__control,
      >input {
        background-color: white !important;
        height: 30px;
        min-height: 35px;
        border: 1px solid #DDD !important;
      }

      input {
        cursor: pointer;
        font-size: 0.8571em;
        color: #9A9A9A;
      }

      .react-select__menu {
        z-index: 99999;
      }

      div,
      input {
        &:focus {
          outline: none !important;
        }
      }

      .option {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block !important;
      }
    }

    .year-container {

      display: flex !important;
      align-items: center;

      .picker-panel {
        z-index: 99999;
      }

      .year-picker {
        border: 1px solid #DDDDDD;
        height: 35px;
        padding: 1px;
        margin-left: 10px;

        &.first {
          border-right-width: 0px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }

        &.last {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          margin-left: 0px;
          border-left-width: 0px;
        }
      }
    }

    .button-alert {
      background-color: $white-color !important;
      color: $black-color !important;
      border: 1px solid $medium-gray !important;
      height: 35px;
      margin-right: 10px;

      &.active {
        background-color: $primary-color !important;
        color: $white-color !important;
      }
    }
  }
}

.datepicker-modal {
  .datepicker-header {
    display: flex !important;
    align-items: center;

    .modal-title {
      width: 100%;
      text-align: center;
      padding-left: 29px;
    }
  }

  .datepicker-body {
    width: 100%;
    padding: 0;

    .rdrCalendarWrapper {
      width: 100%;

      .rdrDateDisplayItemActive {
        border-color: #ef6d2e !important;
      }

      .rdrDateDisplay {
        color: $primary-color !important
      }

      .rdrInputRangeInput {
        height: 30px;
      }

      .rdrDayHovered {
        //border: 1px solid #ef6d2e !important;
        //border-radius: 1.333em;
      }

      .rdrDayToday .rdrDayNumber span:after {
        background: #c01f0d !important;
      }

      .rdrSelected,
      .rdrInRange,
      .rdrStartEdge,
      .rdrEndEdge {
        background: #ef6d2e !important;
      }

      .rdrDayStartPreview {
        border-color: #ef6d2e !important;
        // border-left: 1px solid #ef6d2e !important;
      }

      .rdrDayEndPreview {
        border-color: #ef6d2e !important;
      }

      .rdrDay {
        border-color: #ef6d2e !important;
      }


      .rdrDayStartPreview,
      .rdrDayInPreview,
      .rdrDayEndPreview {
        border-color: #ef6d2e !important;
      }

      .rdrMonth {
        width: 100%;
      }

      .rdrStartEdge .rdrEndEdge .rdrInRange {
        color: $primary-color !important;
      }
    }

    .button-date-confirm {
      align-self: center;
      margin: 10px auto;
      display: block;
    }
  }
}


.filter-search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;

  .input-form {
    position: relative;
    width: 210px;

    .icon-search {
      position: absolute;
      left: 6px;
      top: 6px;
      font-size: 20px;
    }

    input {
      border-radius: 4px;
      padding-left: 30px !important;
      height: 35px;
    }
  }
}

.filter-lib {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;

  @media screen and (max-width: 576px){
    padding-top: 5px;
  }

  &#tags {
    margin-right: 0px;
    @media screen and (max-width: 1024px){
      padding-top: 5px;
    }
  }

  &#year {
    margin-right: 0px;
    @media screen and (max-width: 1024px){
      padding-top: 5px;
    }
  }
}