//all button props
.v2-primary-btn,
.v2-secondary-btn {
    height: 50px;
    border-radius: 10px !important;

    &:hover,
    :focus {
        background-color: transparent !important;
    }
}

//primary button
.v2-primary-btn {
    &:not(.btn-outline-primary) {
        background-color: $v2-primary-color;

        &:hover,
        &:focus,
        &:active,
        &:active:hover {
            background-color: $v2-primary-color !important;
        }
    }

    &.disabled {
        background-color: $v2-primary-color !important;
    }

    &.btn-outline-primary {
        background-color: transparent !important;
        color: $v2-primary-color !important;
        border: 2px solid $v2-primary-color;

        &:hover,
        &:focus {
            opacity: 0.7;
        }
    }
}

//secondary button and outline
.v2-secondary-btn {
    &:not(.btn-outline-secondary) {
        background-color: $v2-secundary-color;

        &:hover,
        &:focus,
        &:active {
            background-color: $v2-secundary-color !important;
        }
    }

    &.btn-outline-secondary {
        background-color: transparent !important;
        color: $v2-secundary-color !important;
        border: 1px solid $v2-secundary-color;

        &:hover,
        &:focus,
        &:active, 
        &:active:hover {
            opacity: 0.7;
            background-color: transparent !important;
            color: $v2-secundary-color !important;
            border-color: $v2-secundary-color !important;
        }
    }
}

.v2-add-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    color: $v2-secundary-color;
    flex: 0;
    width: fit-content;
    cursor: pointer;
    text-decoration: none !important;
    padding-left: 20px;
    padding-right: 20px;

    &:hover {
        opacity: 0.7;
    }

    .icon-container {
        padding: 10px;
        background-color: $v2-light-color;
        border-radius: 8px;
        margin-right: 11px;

        svg {
            font-size: 22px;
            color: $v2-secundary-color;
        }
    }
}

//buttongroup
.v2-button-group {
    display: flex;
    justify-content: center;
    // background-color: #e5e9f0;
    // border-radius: 100px;
    // flex: 1;
    margin: 30px 30px 10px 30px;
    overflow-y: scroll;

    .border-group {
        background-color: #e5e9f0;
        border-radius: 100px;
        display: flex;
        padding: 8px 6px;
        flex-wrap: wrap;

        @media (max-width: #{768px - 1px}) {
            flex-direction: column;
            padding: 20px;
            // border-radius: 35px;
            // flex-flow: wrap;

            .button {
                margin: auto;
                flex: 1;
            }
        }

        .button {
            // flex: 1;
            background-color: transparent;
            color: #5f5f5f;
            // margin: 8px;
            margin-inline: 4px;
            border-radius: 90px;
            height: 32px;
            font-family: "Manrope";
            font-size: 14px;
            align-items: center;
            justify-content: center;
            display: flex;
            cursor: pointer;
            padding: 5px 12px 8px;
            white-space: nowrap;
            font-weight: 600;

            &:hover {
                background-color: $v2-secundary-color;
                color: white;
                text-decoration: none;
            }

            &.active {
                background-color: $v2-secundary-color;
                color: white;
            }
        }
    }

    &.link-group {
        margin: 0px;
        justify-content: start;
        margin-top: 10px;

        .border-group {
            background-color: white;
            padding: 0px;

            .button {
                // flex: 1;
                background-color: transparent;
                color: $v2-primary-color;
                border: 2px solid $v2-primary-color;
                margin-bottom: 10px;

                &.active, &:hover {
                    background-color: $v2-primary-color;
                    color: white !important;

                    &.positive {
                        background-color: #20a74c;
                    }

                    &.low {
                        background-color: #f5af19;
                    }

                    &.medium {
                        background-color: #ef6615;
                    }

                    &.high {
                        background-color: #ef6d2e;
                    }
                }

                &.positive {
                    border-color: #20a74c;
                    color: #20a74c;
                }

                &.low {
                    border-color: #f5af19;
                    color: #f5af19;
                    margin-bottom: 10px;
                }

                &.medium {
                    border-color: #ef6615;
                    color: #ef6615;
                    margin-bottom: 10px;
                }

                &.high {
                    border-color: #ef6d2e;
                    color: #ef6d2e;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.v2-button-group::-webkit-scrollbar {
    display: none;
}
