.material-filter {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;

    @include mobile {
        justify-content: center;
     }

    .v2-input-select,
    .v2-input-select,
    .v2-yearpicker,
    .v2-textinput,
    .v2-daterangepicker {
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .v2-input-select,
    .v2-input-select,
    .v2-textinput {
        width: 250px;
    }

    .v2-textinput {
        margin-bottom: 0px;
        margin-top: 10px;

        svg {
            position: absolute;
            right: 12px;
            top: 12px;
            z-index: 99;
            font-size: 20px;
        }
    }

    .chips {
        max-width: calc(100vw - 650px);
        display: block;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mobile {
            display: none;
        }

        .badge {
            height: 30px;
            margin: 10px 0px 10px 10px;
            border: 1px solid $v2-info-color;
            line-height: 20px;
        }
    }

    .filter-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 42px;
        position: relative;
        display: none;
        margin-left: 10px;
        background-color: $v2-mini-btn-bg-color;
        padding:10px;
        border-radius: 8px;

        > svg {
            font-size: 22px;
            color: $v2-secundary-color
        }

        .has-filter {
            position: absolute;
            top: 10px;
            left: 25px;
            background-color: $warning-color;
            height: 10px;
            width: 10px;
            border-radius: 100%;
        }

        > svg {
            margin-right: 10px;
            height: 20px;
            width: 20px;
        }

        @include mobile {
            display: block;
            width: 42px;

            > svg {
                margin-right: 0px;
            }
            > span {
                display: none;
            }
        }
        &:hover,
        &:active,
        &:focus,
        &:active:hover {
            background-color: $v2-mini-btn-bg-color !important;
            color:$v2-secundary-color !important;
        }

    }
}

.custom-table {
    tbody {
        tr:empty {
            display: none;
            background-color: transparent !important;
            box-shadow: none !important;
        }
    }
}

.material-filter-footer {
    display: flex;

    .btn-link {
        width: initial !important;
    }
}

.lib-row {
    h5 {
        color: $v2-primary-color;
        margin-bottom: 5px;
    }
    .lib-row-info {
        align-items: center;
        display: flex;
        flex-wrap: wrap;

        p {
            margin-bottom: 0px;
            margin-right: 20px;

            @include mobile {
                margin-bottom: 10px;
            }
        }

        .badge-tag {
            //margin-left: 20px;
            margin-bottom: 0px;
            font-family: Manrope;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            height: 28px;
            background: #e6f7ff;
            border-radius: 100px;
            color: $v2-primary-color;
            border-width: 0px;
        }
    }
}

.modal-material-filter {
    .v2-input-select,
    .v2-input-select,
    .v2-yearpicker,
    .v2-textinput {
        margin-bottom: 10px;
    }

    .v2-yearpicker {
        max-width: 200px;
        margin: 10px auto;
    }
}

.badge-public {
    margin-right: 20px;
    color: white;
    border-radius: 90px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 5px 12px 5px;

    &.info {
        background-color: $v2-info-color;
    }

    &.success {
        background-color: $v2-warning-color;
    }
}
